import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    color: theme.palette.grey[500],
  },
  greyContent: {
    backgroundColor: theme.palette.background.default,
  },
}));

function SimpleDialog({
  openDialog,
  handleClose,
  dialogTitle,
  dialogText,
  cancelButtonText,
  confirmButtonText,
  confirmButtonLoading,
  handleCancelButton,
  handleConfirmButton,
  content,
  maxWidth = 'xl',
  disableConfirmButton,
  tooltipConfirmButton,
  actionButton,
  greyContent,
}) {
  const classes = useStyles();

  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth={maxWidth}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          {dialogTitle ? <Grid item>{dialogTitle}</Grid> : null}
          <Grid item>
            <Grid container>
              {actionButton ? <Grid item>{actionButton}</Grid> : null}
              <Grid item>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className={greyContent ? classes.greyContent : null}>
        {dialogText ? <DialogContentText marginBottom={2}>{dialogText}</DialogContentText> : null}
        {content}
      </DialogContent>
      <DialogActions>
        {cancelButtonText && !confirmButtonLoading && (
          <Button onClick={handleCancelButton || handleClose} variant="contained" color="default">
            {cancelButtonText}
          </Button>
        )}

        {confirmButtonText ? (
          <Tooltip
            title={
              tooltipConfirmButton ? (
                <Typography align="center">{tooltipConfirmButton}</Typography>
              ) : (
                ''
              )
            }
          >
            <Box marginLeft={1}>
              <LoadingButton
                loading={confirmButtonLoading}
                onClick={handleConfirmButton}
                disabled={disableConfirmButton}
                variant="contained"
                color="primary"
                autoFocus
              >
                {confirmButtonText}
              </LoadingButton>
            </Box>
          </Tooltip>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;
