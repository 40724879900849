import { Box, Chip, Grid, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { makeStyles } from '@mui/styles';
import CompanyAutocomplete from '../../../Common/CompanyAutocomplete';
import OmieConfigAutocomplete from '../../../Common/OmieConfigAutocomplete';

const useStyles = makeStyles(() => ({
  helpIcon: {
    marginRight: -5,
    marginLeft: 10,
    marginBottom: -4,
  },
}));

function TopTab({ orderForm, setOrderForm, setSelectedCompany, setSnackbar }) {
  const classes = useStyles();

  const handleChangeCompany = (_, newValue) => {
    if (newValue) {
      setOrderForm((oldFields) => ({
        ...oldFields,
        company: newValue,
      }));
    } else {
      setOrderForm((oldFields) => ({
        ...oldFields,
        company: null,
      }));
    }
  };

  const handleChangeOmieAction = (_, newValue) => {
    if (newValue?.id) {
      setOrderForm((oldFields) => ({
        ...oldFields,
        othersIds: {
          ...oldFields.othersIds,
          idOmieActionConfig: newValue.id,
        },
      }));
    } else {
      setOrderForm((oldFields) => ({
        ...oldFields,
        othersIds: {
          ...oldFields.othersIds,
          idOmieActionConfig: null,
        },
      }));
    }
  };

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item xs={4}>
        <Chip
          color="primary"
          label={
            orderForm.orderId ? (
              <Grid container alignItems="center" justifyContent="space-between">
                Cód. {orderForm.orderId}
                {orderForm.othersIds ? (
                  <Tooltip
                    placement="bottom-start"
                    title={
                      <Grid container direction="column" spacing={1}>
                        {Object.keys(orderForm.othersIds).map((key) => (
                          <Grid item key={`${key} ${orderForm.othersIds[key]}`}>
                            <Grid container direction="column">
                              <Typography variant="caption">{key}</Typography>
                              <Typography variant="caption">{orderForm.othersIds[key]}</Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    }
                  >
                    <Box>
                      <HelpIcon width="30" className={classes.helpIcon} />
                    </Box>
                  </Tooltip>
                ) : null}
              </Grid>
            ) : (
              'Novo pedido'
            )
          }
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CompanyAutocomplete
              setSnackbar={setSnackbar}
              handleOnChange={handleChangeCompany}
              selectedCompany={orderForm.company}
              setSelectedCompany={setSelectedCompany}
              error={!orderForm.company}
              disabled={
                !(
                  orderForm.status === 'Orçamento' ||
                  orderForm.status === 'Pedido criado' ||
                  orderForm.status === 'Pedido confirmado'
                )
              }
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <OmieConfigAutocomplete
              label="Ação na Omie"
              companyId={orderForm.company?._id || orderForm?.company || null}
              routeName="omieactions"
              handleOnChange={handleChangeOmieAction}
              selectedValue={orderForm.othersIds?.idOmieActionConfig || null}
              disabled={!orderForm.company}
              variant="filled"
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TopTab;
