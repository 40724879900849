import { Alert, Box, Checkbox, Grid, Paper, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../../Common/SimpleDialog';
import getTasks from '../../../../services/melhorGestao/tasks';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import { formatHandleError } from '../../../../helpers/formatData';

function UserStaffDialog({ openDialogStaff, handleCloseDialogStaff, userForm }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [staffTasks, setStaffTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasks = await getTasks({ userForm, setLoadingAllTasks: setLoading });

        setStaffTasks(tasks);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as permissões',
          error,
        });
      }
    };
    fetchData();
  }, []);

  const handleChangeSelectedTasks = (index) => {
    const newTasks = staffTasks;
    newTasks[index].active = !newTasks[index].active;
    return setStaffTasks(() => [...newTasks]);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      await Axios.post('/tasks/setActive', {
        tasks: staffTasks,
        userId: userForm.userId,
      });

      handleCloseDialogStaff();
      return setLoading(false);
    } catch (error) {
      setLoading(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao alterar as permissões',
        error,
      });
    }
  };

  return (
    <Grid>
      <SimpleBackdrop loading={loading} />
      <SimpleDialog
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        maxWidth="md"
        content={
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Paper>
                <Box padding={2}>
                  <Box marginBottom={1}>
                    <Typography variant="h3">Tarefas</Typography>
                  </Box>
                  <Grid container>
                    {staffTasks.map((staffTask, index) => (
                      <Grid item key={staffTask.name} xs={3}>
                        <Grid
                          container
                          wrap="nowrap"
                          alignItems="center"
                          onClick={() => handleChangeSelectedTasks(index)}
                        >
                          <Grid item>
                            <Checkbox checked={staffTask.active} />
                          </Grid>
                          <Grid item>{staffTask.name}</Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            {snackbar.open && (
              <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
            )}
          </Grid>
        }
        dialogTitle="Permissões"
        handleCancelButton={handleCloseDialogStaff}
        handleClose={handleCloseDialogStaff}
        handleConfirmButton={handleConfirm}
        openDialog={openDialogStaff}
      />
    </Grid>
  );
}

export default UserStaffDialog;
