const newOmieConfigurationAction = {
  configId: null,
  description: null,
  otherCompany: null,
  tagName: null,
  omieTaxScenario: null,
  omieCategoryCode: null,
  omieCurrentAccountCode: null,
  omiePriceTableCode: null,
};

export default newOmieConfigurationAction;
