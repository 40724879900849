import React, { memo } from 'react';
import { Typography } from '@mui/material';
import orderStatusColor from '../../../../constant/orderStatusColor';
import { skipFirstWord } from '../../../../helpers/formatData';
import filterOrderStatusInitial from '../../../../constant/filterOrderStatusInitial';

function OrderStatusTypography({ orderStatus, onlyStatusName, useCustomStatusName }) {
  let status = orderStatus;

  if (onlyStatusName) {
    status = skipFirstWord(orderStatus);
  } else if (useCustomStatusName) {
    status =
      filterOrderStatusInitial.find((statusInitial) => statusInitial.name === orderStatus)
        ?.customName || orderStatus;
  }

  return <Typography color={orderStatusColor(orderStatus)}>{status}</Typography>;
}

export default memo(OrderStatusTypography);
