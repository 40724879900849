const filterOrderStatusInitial = [
  {
    name: 'Orçamento',
    customName: '',
    active: false,
  },
  {
    name: 'Pedido criado',
    customName: 'Pedido de Venda',
    active: false,
  },
  {
    name: 'Pedido confirmado',
    customName: 'Acerto Financeiro',
    active: false,
  },
  {
    name: 'Pedido faturado',
    customName: 'Faturado',
    active: false,
  },
  {
    name: 'Pedido embalado',
    customName: 'Separar Estoque',
    active: false,
  },
  {
    name: 'Pedido enviado',
    customName: 'Entrega',
    active: false,
  },
  {
    name: 'Pedido entregue',
    customName: 'Entregue',
    active: false,
  },
  {
    name: 'Pedido concluído',
    customName: 'Concluído',
    active: false,
  },
  {
    name: 'Pedido não entregue',
    customName: 'Não entregue',
    active: false,
  },
  {
    name: 'Pedido devolvido',
    customName: 'Devolvido',
    active: false,
  },
  {
    name: 'Pedido cancelado',
    customName: 'Cancelado',
    active: false,
  },
];

export default filterOrderStatusInitial;
