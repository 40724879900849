import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import PrintOrderPdf from './PrintOrderPdf';

function PrintOrderIconButton({ orderForm, formChanged, selectedCompany, seller }) {
  const [openDialogPrintOrder, setOpenDialogPrintOrder] = useState(false);

  const handleCloseDialogPrintOrder = () => setOpenDialogPrintOrder(false);

  const [blockPrint, setBlockPrint] = useState(true);
  const [blockPrintMessage, setBlockPrintMessage] = useState('');

  useEffect(() => {
    if (!orderForm.company) {
      setBlockPrintMessage('Escolha a empresa que fará a venda');
      return setBlockPrint(true);
    }
    if (!orderForm.source) {
      setBlockPrintMessage('Escolha a origem do pedido');
      return setBlockPrint(true);
    }
    if (!orderForm.items || orderForm.items.length === 0) {
      setBlockPrintMessage('Escolha os produtos');
      return setBlockPrint(true);
    }
    if (!orderForm.customer || !orderForm.customer.name) {
      setBlockPrintMessage('Escolha o cliente');
      return setBlockPrint(true);
    }
    if (!orderForm.shipping.method || !orderForm.shipping.company) {
      setBlockPrintMessage('Escolha uma forma de entrega');
      return setBlockPrint(true);
    }
    setBlockPrintMessage('Imprimir pedido');
    return setBlockPrint(false);
  }, [orderForm, formChanged]);

  return (
    <Tooltip title={<Typography align="center">{blockPrintMessage}</Typography>}>
      <Box>
        <IconButton onClick={() => setOpenDialogPrintOrder(true)} disabled={blockPrint}>
          <PrintIcon />
        </IconButton>

        {openDialogPrintOrder ? (
          <SimpleDialog
            openDialog={openDialogPrintOrder}
            handleClose={handleCloseDialogPrintOrder}
            dialogTitle="Imprimir pedido"
            content={
              <PrintOrderPdf selectedCompany={selectedCompany} seller={seller} order={orderForm} />
            }
          />
        ) : null}
      </Box>
    </Tooltip>
  );
}

export default PrintOrderIconButton;
