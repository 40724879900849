import Axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState, useEffect } from 'react';
import CustomInput from '../../CustomInput';
import SimpleDialog from '../../Common/SimpleDialog';
import TableSettingsOccurrenceType from './TableSettingsOccurrenceTypes';

const useStyles = makeStyles((theme) => ({
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const menuItems = [{ name: 'Tipos de ocorrências' }];

function SettingsCategory() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [menuItem, setMenuItem] = useState(menuItems[0].name);
  const [categoriesList, setCategoriesList] = useState([]);

  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState('');

  const [openDialogCreateCategory, setOpenDialogCreateCategory] = useState(false);
  const handleOpenDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(true), []);
  const handleCloseDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(false), []);

  const [refreshToken, setRefreshToken] = useState(Math.random());
  const handleRefresh = () => setRefreshToken(Math.random());

  const handleCreateCategory = async () => {
    try {
      if (menuItem === 'Tipos de ocorrências') {
        await Axios.post('/occurrences/types', {
          name: newCategoryName,
        });
      }
      handleCloseDialogCreateCategory();
      handleRefresh();
    } catch (error) {
      let errorMessage = `Algum erro ocorreu ao adicionar "${menuItem}"`;
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (menuItem === 'Tipos de ocorrências') {
          const response = await Axios.get('/occurrences/types');
          setCategoriesList(response.data);
        }
      } catch (error) {
        let errorMessage = 'Algum erro ocorreu ao buscar os itens';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        setSnackbar({
          message: errorMessage,
          open: true,
          type: 'error',
        });
      }
    };
    fetchData();
  }, [menuItem, refreshToken]);

  useEffect(() => {
    if (!newCategoryName) {
      return setDisableConfirmButton(true);
    }
    return setDisableConfirmButton(false);
  }, [newCategoryName, openDialogCreateCategory]);

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Paper>
            <Box padding={2}>
              <Typography variant="h5">Categorias</Typography>
            </Box>

            <MenuList>
              {menuItems.map((item) => (
                <MenuItem
                  key={item.name}
                  onClick={() => setMenuItem(item.name)}
                  className={menuItem === item.name ? classes.menuItemSelected : null}
                >
                  {item.name}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Grid>

        <Grid item sm={8}>
          <Paper>
            <Box padding={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">{menuItem}</Typography>
                </Grid>
                {menuItem === 'Tipos de ocorrências' ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenDialogCreateCategory}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              <Box marginTop={2}>
                {menuItem === 'Tipos de ocorrências' ? (
                  <TableSettingsOccurrenceType occurrencesList={categoriesList} />
                ) : null}
              </Box>
            </Box>
          </Paper>
          {openDialogCreateCategory ? (
            <SimpleDialog
              content={
                <Box>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <CustomInput
                        label="Nome"
                        value={newCategoryName}
                        onChange={(event) => setNewCategoryName(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                  >
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                      {snackbar.message}
                    </Alert>
                  </Snackbar>
                </Box>
              }
              dialogTitle={menuItem}
              handleClose={handleCloseDialogCreateCategory}
              openDialog={openDialogCreateCategory}
              handleConfirmButton={handleCreateCategory}
              disableConfirmButton={disableConfirmButton}
              confirmButtonText="Confirmar"
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default SettingsCategory;
