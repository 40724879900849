import React from 'react';
import { Box, Typography } from '@mui/material';
import SimpleDialog from '../../../../../Common/SimpleDialog';

function DialogRemoveConfiguration({
  omieConfiguration,
  handleCloseDialog,
  handleConfirm,
  handleCancel,
}) {
  return (
    <SimpleDialog
      dialogTitle="Remover configuração"
      openDialog={!!omieConfiguration}
      handleConfirmButton={handleConfirm}
      handleClose={handleCloseDialog}
      handleCancelButton={handleCancel}
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      content={
        <Box width={320}>
          <Typography>{`Deseja realmente remover a configuração '${omieConfiguration.name}'?`}</Typography>
        </Box>
      }
    />
  );
}

export default DialogRemoveConfiguration;
