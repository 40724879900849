import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../Common/SimpleDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import { updateProduct } from '../../../services/melhorGestao/products';

function MassProcessProductsDialog({
  openDialogProcessProducts,
  handleCloseDialogProcessProducts,
  checkedProducts,
  handleRefreshAll,
}) {
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [productsList, setProductsList] = useState([]);

  const getUpdatedProductList = useCallback(async () => {
    try {
      setLoadingProcessing(true);

      const response = await Axios.get('/catalog/products/list/id', {
        params: {
          productsIds: checkedProducts,
        },
      });
      const updatedProductList = response.data.productsList;

      setLoadingProcessing(false);
      handleRefreshAll();
      return setProductsList(updatedProductList);
    } catch (error) {
      setLoadingProcessing(false);
      console.log(error);
      throw new Error('Algum erro ocorreu ao tentar obter a lista de produtos');
    }
  }, [checkedProducts]);

  useEffect(() => {
    getUpdatedProductList();
  }, []);

  const [openProductsStatusDialog, setOpenProductsStatusDialog] = useState(false);
  const handleOpenProductsStatusDialog = () => setOpenProductsStatusDialog(true);
  const handleCloseProductsStatusDialog = () => setOpenProductsStatusDialog(false);

  const [productStatus, setProductStatus] = useState('active');
  const [productForSale, setProductForSale] = useState(true);

  const [loadingUpdateProduct, setLoadingUpdateProduct] = useState(false);

  const handleConfirmUpdateProductStatus = async () => {
    handleCloseProductsStatusDialog();
    setLoadingUpdateProduct(true);

    await checkedProducts.reduce(async (promise, productId) => {
      await promise;

      try {
        await updateProduct({
          product: {
            productId,
            status: productStatus,
            'active.forSale': productForSale,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }, Promise.resolve());

    setLoadingUpdateProduct(false);
    handleRefreshAll();
  };

  return (
    <>
      <SimpleDialog
        content={
          <>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {productsList.length > 0
                        ? 'Altera o status dos produtos selecionados'
                        : 'Nenhum produto foi selecionado.'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!(productsList.length > 0)}
                      onClick={handleOpenProductsStatusDialog}
                    >
                      Alterar status dos produtos
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>

            <SimpleBackdrop loading={loadingProcessing} />
            <SimpleBackdrop loading={loadingUpdateProduct} />
          </>
        }
        dialogTitle="Editar produtos"
        dialogText={
          productsList.length === 1
            ? `Foi selecionado ${productsList.length} produto.`
            : `Foram selecionados ${productsList.length} produtos.`
        }
        handleClose={handleCloseDialogProcessProducts}
        openDialog={openDialogProcessProducts}
      />

      {openProductsStatusDialog && (
        <SimpleDialog
          openDialog={openProductsStatusDialog}
          handleClose={handleCloseProductsStatusDialog}
          dialogTitle="Alterar status dos produtos"
          dialogText="Altera o status dos produtos selecionados"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseProductsStatusDialog}
          handleConfirmButton={handleConfirmUpdateProductStatus}
          content={
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">
                      {productStatus === 'active' ? 'Ativo' : 'Inativo'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          value={productStatus === 'active'}
                          onClick={() =>
                            setProductStatus(productStatus === 'active' ? 'unactive' : 'active')
                          }
                          checked={productStatus === 'active'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Divider />
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">
                      {productForSale ? 'Para venda' : 'Uso e consumo'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          value={productForSale}
                          onClick={() => {
                            setProductForSale(!productForSale);
                          }}
                          checked={productForSale}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}

export default MassProcessProductsDialog;
