import Axios from 'axios';
import { Box, Button, Grid, Tooltip, Typography, Link as LinkMaterial } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { formatHandleError } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import ShippingDialog from './ShippingDialog';
import VolumesDialog from './VolumesDialog';

const useStyles = makeStyles({
  companyPicture: {
    height: 25,
  },
});

function ShippingTab({
  orderForm,
  setOrderForm,
  formChanged,
  handleSaveForm,
  selectedOrderCompany,
  setSnackbar,
}) {
  const classes = useStyles();

  const [trackingMelhorEnvio, setTrackingMelhorEnvio] = useState('');
  const handleChangeIdMelhorEnvio = (event) => setTrackingMelhorEnvio(event.target.value);
  const [openDialogIdMelhorEnvio, setOpenDialogIdMelhorEnvio] = useState(false);

  const handleOpenDialogIdMelhorEnvio = async () => {
    if (orderForm.othersIds && orderForm.othersIds.idMelhorEnvio) {
      const response = await Axios.get('/shipping/melhorenvio-orders', {
        params: {
          searchText: orderForm.othersIds.idMelhorEnvio,
        },
      });

      const melhorEnvioOrders = response.data;

      if (melhorEnvioOrders.data && melhorEnvioOrders.data.length) {
        setTrackingMelhorEnvio(
          melhorEnvioOrders.data[0].tracking || melhorEnvioOrders.data[0].authorization_code,
        );
      }
    }
    setOpenDialogIdMelhorEnvio(true);
  };

  const handleCloseDialogIdMelhorEnvio = () => setOpenDialogIdMelhorEnvio(false);

  const handleConfirmIdMelhorEnvio = async () => {
    try {
      const response = await Axios.get('/shipping/melhorenvio-orders', {
        params: {
          searchText: trackingMelhorEnvio,
        },
      });
      const melhorEnvioOrders = response.data;

      if (melhorEnvioOrders.data && melhorEnvioOrders.data.length) {
        handleSaveForm({
          updateFields: {
            othersIds: {
              ...orderForm.othersIds,
              idMelhorEnvio: melhorEnvioOrders.data[0].id,
            },
          },
        });

        setOpenDialogIdMelhorEnvio(false);
      } else {
        setSnackbar({
          message: 'Não encontramos no Melhor Envio este rastreio',
          open: true,
          type: 'error',
        });
      }
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar o rastreio no Melhor Envio',
        error,
      });
    }
  };

  const [openDialogShipping, setOpenDialogShipping] = useState(false);

  const handleOpenDialogShipping = useCallback(() => {
    if (formChanged) {
      return setSnackbar({
        open: true,
        message: 'Salve o pedido antes de alterar a entrega.',
        type: 'info',
      });
    }

    if (!selectedOrderCompany) {
      return setSnackbar({
        open: true,
        message: 'Selecione uma empresa para continuar.',
        type: 'error',
      });
    }

    return setOpenDialogShipping(true);
  }, []);

  const handleCloseDialogShipping = useCallback(() => setOpenDialogShipping(false), []);

  const handleSelectShipping = useCallback(
    ({ shipping }) => {
      setOrderForm((oldFields) => ({
        ...oldFields,
        shipping,
      }));

      return handleCloseDialogShipping();
    },
    [handleCloseDialogShipping],
  );

  const [openVolumesDialog, setOpenVolumesDialog] = useState(false);
  const handleOpenVolumesDialog = () => setOpenVolumesDialog(true);
  const handleCloseVolumesDialog = () => setOpenVolumesDialog(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Entrega</Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                {orderForm.othersIds && orderForm.othersIds.idMelhorEnvio ? (
                  <Button variant="contained" onClick={handleOpenDialogIdMelhorEnvio}>
                    Atualizar rastreio
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {orderForm.customer && !orderForm.customer.name
                        ? 'Para selecionar a entrega você precisa escolher o cliente.'
                        : null}
                      {orderForm.customer &&
                      orderForm.customer.name &&
                      orderForm.items &&
                      orderForm.items.length === 0 &&
                      orderForm.status !== 'Pedido devolvido'
                        ? 'Para selecionar a entrega você precisa escolher os produtos.'
                        : null}
                      {orderForm.customer &&
                      orderForm.customer.name &&
                      orderForm.items &&
                      orderForm.items.length > 0 &&
                      (orderForm.status === 'Orçamento' ||
                        orderForm.status === 'Pedido criado' ||
                        orderForm.status === 'Pedido confirmado' ||
                        orderForm.status === 'Pedido embalado') &&
                      orderForm.status !== 'Pedido devolvido'
                        ? 'Selecione um método de entrega'
                        : null}
                      {(orderForm.items &&
                        orderForm.items.length > 0 &&
                        orderForm.status !== 'Orçamento' &&
                        orderForm.status !== 'Pedido criado' &&
                        orderForm.status !== 'Pedido confirmado' &&
                        orderForm.status !== 'Pedido embalado') ||
                      orderForm.status === 'Pedido devolvido'
                        ? 'A entrega não pode ser alterada.'
                        : null}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleOpenDialogShipping}
                      disabled={
                        !(orderForm.customer && orderForm.customer.name) ||
                        (orderForm.status !== 'Orçamento' &&
                          orderForm.status !== 'Pedido criado' &&
                          orderForm.status !== 'Pedido confirmado' &&
                          orderForm.status !== 'Pedido faturado' &&
                          orderForm.status !== 'Pedido embalado') ||
                        !orderForm.items ||
                        orderForm.items.length === 0
                      }
                    >
                      Escolher entrega
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {orderForm.shipping?.method && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item>
                <img
                  src={orderForm.shipping.companyPicture}
                  alt={orderForm.shipping.company}
                  className={classes.companyPicture}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Typography variant="caption">Método</Typography>
                  {orderForm.othersIds?.idTrackingShipping ? (
                    <Typography>
                      <LinkMaterial
                        href={
                          orderForm.shipping.trackingLink ||
                          `https://melhorrastreio.com.br/rastreio/${orderForm.othersIds.idTrackingShipping}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {orderForm.shipping.company} {orderForm.shipping.method}
                      </LinkMaterial>
                    </Typography>
                  ) : (
                    <Typography>
                      {orderForm.shipping.company} {orderForm.shipping.method}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {orderForm.shipping?.deliveryRange?.min && orderForm.shipping?.deliveryRange?.max ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Prazo</Typography>
                    <Typography>
                      de {orderForm.shipping.deliveryRange.min} a{' '}
                      {orderForm.shipping.deliveryRange.max} dias úteis
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item>
                <Button variant="outlined" onClick={handleOpenVolumesDialog}>
                  Volumes
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {orderForm.shipping?.address?.postalCode ? (
            <Grid item xs={12}>
              <Grid container direction="column">
                <Typography variant="caption">Endereço</Typography>
                <Typography>
                  {orderForm.shipping.address.street
                    ? `${orderForm.shipping.address.street}, `
                    : null}
                  {orderForm.shipping.address.streetNumber
                    ? `${orderForm.shipping.address.streetNumber}, `
                    : null}
                  {orderForm.shipping.address.complement
                    ? `${orderForm.shipping.address.complement}, `
                    : null}
                  {orderForm.shipping.address.district
                    ? `${orderForm.shipping.address.district}, `
                    : null}
                  {orderForm.shipping.address.city}/{orderForm.shipping.address.state} - CEP:{' '}
                  {orderForm.shipping.address.postalCode}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
      {openDialogIdMelhorEnvio ? (
        <SimpleDialog
          openDialog={openDialogIdMelhorEnvio}
          handleClose={handleCloseDialogIdMelhorEnvio}
          dialogTitle="Insira o rastreio do Melhor Envio"
          dialogText="Digite abaixo o número de rastreio usado do Melhor Envio."
          content={
            <CustomInput
              name="idMelhorEnvio"
              label="Melhor Envio Rastreio"
              variant="outlined"
              value={trackingMelhorEnvio}
              onChange={handleChangeIdMelhorEnvio}
            />
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Confirma"
          handleCancelButton={handleCloseDialogIdMelhorEnvio}
          handleConfirmButton={handleConfirmIdMelhorEnvio}
        />
      ) : null}
      {openDialogShipping ? (
        <ShippingDialog
          setSnackbar={setSnackbar}
          handleCloseDialogShipping={handleCloseDialogShipping}
          handleSelectShipping={handleSelectShipping}
          openDialogShipping={openDialogShipping}
          orderForm={orderForm}
          selectedOrderCompany={selectedOrderCompany}
        />
      ) : null}
      {openVolumesDialog ? (
        <VolumesDialog
          volumes={orderForm.shipping.volumes?.volumesList}
          openVolumesDialog={openVolumesDialog}
          handleCloseVolumesDialog={handleCloseVolumesDialog}
          setOrderForm={setOrderForm}
          orderIsInvoiced={orderForm.invoice?.status === 'aprovado'}
        />
      ) : null}
    </Grid>
  );
}

export default ShippingTab;
