import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { numberToReal } from '../../../../../helpers/formatData';
import SimpleImage from '../../../../Common/SimpleImage';
import DialogProductImage from '../../../../Products/Dialogs/DialogProductImages';

function ProductsTable({ itemsList, devolution }) {
  const [selectProductImage, setSelectProductImage] = useState(null);

  const handleOpenDialogProductImages = (product) => {
    if (product.images && product.images.length) {
      setSelectProductImage(product);
    }
  };

  const handleCloseDialogProductImages = () => setSelectProductImage(null);

  return (
    <Paper elevation={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">SKU</TableCell>
            <TableCell align="center">Imagem</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell align="center">Preço</TableCell>
            <TableCell align="center">Quantidade</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsList && itemsList.length > 0 ? (
            itemsList.map((item) => (
              <TableRow key={item.productId}>
                <TableCell align="center">{item.customSku || item.productId}</TableCell>
                <TableCell
                  align="center"
                  width={60}
                  onClick={() => handleOpenDialogProductImages(item)}
                >
                  {item.images && item.images.length > 0 ? (
                    <SimpleImage
                      src={item.images[0].src}
                      imgHeight={40}
                      imgWidth={40}
                      alt={item.name}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <SimpleImage
                      src="/assets/imgs/no_image.png"
                      imgHeight={30}
                      imgWidth={30}
                      alt={item.name}
                    />
                  )}
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell align="center" width={100}>
                  R$ {numberToReal(item.selectedPrice)}
                </TableCell>
                <TableCell align="center" width={100}>
                  {item.quantity}
                </TableCell>
                <TableCell align="center" width={100}>
                  R$ {numberToReal(item.selectedPrice * item.quantity)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              {devolution ? (
                <TableCell colSpan={6} align="center">
                  <Typography>
                    Todos itens deste pedido foram devolvidos, confira mais a baixo
                  </Typography>
                </TableCell>
              ) : (
                <TableCell colSpan={6} align="center">
                  <Typography>Não há nenhum item neste pedido</Typography>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
      {selectProductImage ? (
        <DialogProductImage
          selectedProduct={selectProductImage}
          openDialogProductImages={!!selectProductImage}
          handleCloseDialogProductImages={handleCloseDialogProductImages}
        />
      ) : null}
    </Paper>
  );
}

export default memo(ProductsTable);
