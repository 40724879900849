const newUser = {
  name: '',
  email: '',
  password: '',
  phones: [],
  documents: {
    razaoSocial: '',
    cpf: '',
    cnpj: '',
    pessoa: 'PF',
    inscricaoEstadual: '',
    birthday: null,
    othersDocuments: [],
  },
  addresses: [],
  customer: true,
  supplier: false,
  shippingCompany: false,
  staff: false,
  tags: [],
  othersIds: {
    sellerUserId: null,
  },
  monthlyPayer: false,
};

export default newUser;
