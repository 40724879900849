import Axios from 'axios';
import React, { memo, useEffect } from 'react';
import FilterSelectOptions from '../FilterSelectOptions';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../../helpers/formatData';

function CompanySelectOptions({
  setSnackbar = () => {},
  multiple = true,
  width = 200,
  size = 'small',
  autoConfirm,
  filtersOptions,
  setFiltersOptions,
  filterCompaniesByIds,
  hideButton,
}) {
  const formatOptions = (company) => ({
    id: company._id,
    name: `${company.companyId} - ${capitalizeString(extractFirstAndLastName(company.name))}`,
    active: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/configuration/companies');
        const companies = response.data;

        if (filterCompaniesByIds?.length > 0) {
          setFiltersOptions(
            companies
              .filter((company) => filterCompaniesByIds.includes(company._id))
              .map((company) => formatOptions(company)),
          );
        } else {
          setFiltersOptions(companies.map((company) => formatOptions(company)));
        }
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as empresas',
          error,
        });
      }
    };
    fetchData();
  }, [filterCompaniesByIds]);

  return (
    <FilterSelectOptions
      inputLabel="Empresa"
      width={width}
      size={size}
      multiple={multiple}
      autoConfirm={autoConfirm}
      filtersOptions={filtersOptions}
      setFiltersOptions={setFiltersOptions}
      hideButton={hideButton}
    />
  );
}

export default memo(CompanySelectOptions);
