import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const availableTasks = [
  'Gerenciar permissões',
  'Visualizar pedidos',
  'Criar pedidos',
  'Gerenciar pedidos',
  'Visualizar produtos',
  'Gerenciar produtos',
  'Visualizar usuários',
  'Criar usuários',
  'Atualizar usuário',
  'Gerenciar empresas',
  'Visualizar categorias',
  'Criar categorias',
  'Gerenciar erros',
];

const getTasks = async ({ userForm, setLoadingAllTasks = () => {}, setSnackbar = () => {} }) => {
  try {
    const formattedTasks = [];
    setLoadingAllTasks(true);

    const response = await Axios.get('/tasks');
    const { tasks } = response.data;
    tasks.forEach((task) => {
      let active = false;
      task.usersIds.forEach((userId) => {
        if (userId === userForm.userId) {
          active = true;
        }
      });

      if (availableTasks.includes(task.name)) {
        formattedTasks.push({
          name: task.name,
          roles: task.roles,
          active,
        });
      }
    });

    setLoadingAllTasks(false);
    return formattedTasks;
  } catch (error) {
    setLoadingAllTasks(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao carregar as permissões do usuário',
      error,
    });

    return [];
  }
};

export default getTasks;
