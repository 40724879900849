const filterOrderSituationsInitial = [
  {
    id: 'Pedido de Venda',
    description: 'Pedido de venda',
    active: false,
  },
  {
    id: 'Acerto Financeiro',
    description: 'Aguardando financeiro',
    active: false,
  },
  {
    id: 'Separar Estoque',
    description: 'Aguardando separação',
    active: false,
  },
  {
    id: 'Faturar',
    description: 'Aguardando faturamento',
    active: false,
  },
  {
    id: 'Faturado',
    description: 'Aguardando envio',
    active: false,
  },
  {
    id: 'Entregue',
    description: 'Entregue',
    active: false,
  },
];

export default filterOrderSituationsInitial;
