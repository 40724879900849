import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { numberToRealWithPeriod } from '../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  situationPaper: {
    width: 145,
    height: 70,
    marginRight: 1,
    padding: 7,
    cursor: 'pointer',
    borderLeftColor: theme.palette.grey[400],
    borderLeftStyle: 'solid',
    borderLeftWidth: '0.5em',
    '&:hover': {
      borderLeftColor: theme.palette.secondary.main,
    },
  },
  situationPaperActive: {
    width: 145,
    height: 70,
    marginRight: 1,
    padding: 7,
    cursor: 'pointer',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: 'solid',
    borderLeftWidth: '0.5em',
  },
}));

function FilterSelectPapers({
  filtersOptions,
  setFiltersOptions,
  handleClickFilter = () => {},
  finances,
}) {
  const classes = useStyles();

  const [temporaryFiltersOptions, setTemporaryFiltersOptions] = useState(filtersOptions);

  const checkIfAnyActive = (filterArray) => {
    let anyActive = false;
    filterArray.forEach((filter) => {
      if (filter.active) {
        anyActive = true;
      }
    });
    return anyActive;
  };

  const handleSetFilter = (index) => {
    const newFilterOptions = temporaryFiltersOptions;

    newFilterOptions.forEach((filterOrder, indexInside) => {
      if (index !== indexInside) {
        newFilterOptions[indexInside].active = false;
      }
    });

    newFilterOptions[index].active = !newFilterOptions[index].active;
    setTemporaryFiltersOptions(() => [...newFilterOptions]);
    return handleClickFilter();
  };

  useEffect(() => {
    setFiltersOptions(temporaryFiltersOptions);
  }, [temporaryFiltersOptions]);

  return (
    <Grid container justifyContent="flex-start" spacing={1}>
      {temporaryFiltersOptions.map((filter, index) => {
        if (!filter.totalResults || filter.totalResults === 0) {
          return null;
        }
        return (
          <Grid
            item
            key={filter.id || filter.name || filter.description}
            onClick={() => handleSetFilter(index)}
          >
            <Paper
              elevation={1}
              className={
                !checkIfAnyActive(temporaryFiltersOptions) || filter.active
                  ? classes.situationPaperActive
                  : classes.situationPaper
              }
            >
              <Typography variant="h3" style={{ color: '#ffa300' }}>
                {finances
                  ? `R$ ${numberToRealWithPeriod(filter.totalResults)}`
                  : filter.totalResults}
              </Typography>
              <Typography variant="body2">{filter.name || filter.description}</Typography>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default FilterSelectPapers;
