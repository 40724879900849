const newOrder = {
  customer: {
    email: '',
    phones: [],
    documents: {
      birthday: null,
      othersDocuments: [],
    },
    officialDocument: {},
  },
  shipping: {
    address: {},
    deliveryRange: {},
    price: 0,
    volume: 1,
    volumes: {
      volumesIds: [],
      volumesList: [
        {
          type: 'Volume',
          quantity: 1,
        },
      ],
    },
  },
  status: 'Orçamento',
  statusPending: '',
  source: 'Loja física',
  company: '65e4f9d5f4456ad6031cf513',
  seller: JSON.parse(localStorage.getItem('user')),
  onlyCompanyStock: false,
  items: [],
  payments: [],
  othersIds: {},
  nfe: '',
  itemsTotal: 0,
  orderTotal: 0,
  discountTotal: 0,
  unblockDiscount: true,
  emailSended: {
    newOrder: true,
    paymentConfirmed: true,
  },
};

export default newOrder;
