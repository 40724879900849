import React, { useState, useCallback, useEffect, memo } from 'react';
import { Box, Fab, Grid, Slide, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import TopBarProducts from './TopBar';
import TableProducts from './Table';
import { searchProducts } from '../../services/melhorGestao/products';
import ProductDetails from './Details';
import MassProcessProductsDialog from './Dialogs/MassProcessProducts';
import FabGroupButtons from '../Common/FabGroupButtons';
import filterProductStatusInitial from '../../constant/filterProductStatusInitial';
import SituationsFiltersPapers from '../Common/SituationsFiltersPapers';
import filterProductSituationsInitial from '../../constant/filterProductSituationsInitial';
import filterOrdenateOptions from '../../constant/filterOrdenateOptions';

const useStyles = makeStyles((theme) => ({
  textLink: {
    cursor: 'pointer',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(12),
  },
  boxCheckedQuantity: {
    bottom: -15,
    right: 0,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
    width: 30,
    height: 30,
    position: 'absolute',
  },
}));

function Products() {
  const classes = useStyles();

  const [openProductDetails, setOpenProductsDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [countProducts, setProductsCount] = useState(0);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [filtersStatus, setFiltersStatus] = useState(filterProductStatusInitial);
  const [situationsFilters, setSituationsFilters] = useState(filterProductSituationsInitial);
  const [filtersOrdenateOptions, setFiltersOrdenateOptions] = useState(filterOrdenateOptions);
  const [filtersByCategories, setFiltersByCategories] = useState([]);
  const [filterByCompany, setFilterByCompany] = useState(null);
  const [filterBySupplier, setFilterBySupplier] = useState(null);
  const [companiesConfigurations, setCompaniesConfigurations] = useState([]);
  const [refreshSearchToken, setRefreshSearchToken] = useState(Math.random());

  const handleRefreshAll = useCallback(() => setRefreshSearchToken(Math.random()), []);

  const getProducts = () => {
    const onlyCategoriesName =
      filtersByCategories.filter((category) => category.active)?.map((category) => category.name) ||
      [];

    const sortBy = filtersOrdenateOptions.find((option) => option.active) || { id: 'productId' };

    searchProducts({
      searchText: searchTextSubmited,
      skip: page * limit - limit,
      limit,
      sortBy: sortBy.id,
      sortDirection: sortBy.id === 'name' && sortBy?.description === 'A - Z' ? 'asc' : 'desc',
      setLoadingProductsList,
      setProductsList,
      setProductsCount,
      setCompaniesConfigurations,
      filterByCompany: filterByCompany?._id,
      filterBySupplier: filterBySupplier?.userId,
      filtersOptions: [...situationsFilters, ...filtersStatus],
      filtersByCategories: onlyCategoriesName,
    });
  };

  useEffect(() => {
    getProducts();
  }, [
    searchTextSubmited,
    filterByCompany?._id,
    filterBySupplier?.userId,
    page,
    limit,
    filtersStatus,
    filtersOrdenateOptions,
    refreshSearchToken,
    filtersByCategories,
  ]);

  const selectProduct = useCallback((product) => {
    setSelectedProduct(product);
    setOpenProductsDetails(true);
  }, []);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, productsList],
  );

  const closeProductDetails = useCallback(() => {
    setOpenProductsDetails(false);
    handleRefreshAll();
  }, []);

  const handleClickFilter = () => {
    getProducts();
    setPage(1);
  };

  const [openDialogProcessProducts, setOpenDialogProcessProducts] = useState();
  const handleOpenDialogProcessProducts = () => setOpenDialogProcessProducts(true);
  const handleCloseDialogProcessProducts = () => setOpenDialogProcessProducts(false);
  const [checkedProducts, setCheckedProducts] = useState([]);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <TopBarProducts
          submitSearchProducts={setSearchTextSubmited}
          filterByCompany={filterByCompany}
          setFilterByCompany={setFilterByCompany}
          filtersStatus={filtersStatus}
          setFiltersStatus={setFiltersStatus}
          filterBySupplier={filterBySupplier}
          filtersOrdenateOptions={filtersOrdenateOptions}
          setFiltersByCategories={setFiltersByCategories}
          setFiltersOrdenateOptions={setFiltersOrdenateOptions}
          setFilterBySupplier={setFilterBySupplier}
          setPage={setPage}
        />
      </Box>
      <Box marginTop={2}>
        <SituationsFiltersPapers
          refreshFiltersWithQuantity={refreshSearchToken}
          situationsFilters={situationsFilters}
          setSituationsFilters={setSituationsFilters}
          handleClickFilter={handleClickFilter}
          products
        />
      </Box>
      <Box marginTop={2}>
        <TableProducts
          companiesConfigurations={companiesConfigurations}
          filterByCompany={filterByCompany}
          productsList={productsList}
          countProducts={countProducts}
          selectProduct={selectProduct}
          selectedProduct={selectedProduct}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          checkedProducts={checkedProducts}
          setCheckedProducts={setCheckedProducts}
          loadingProductsList={loadingProductsList}
        />
      </Box>

      {openProductDetails && (
        <Box>
          <ProductDetails
            openProductDetails={openProductDetails}
            selectedProduct={selectedProduct}
            closeProductDetails={closeProductDetails}
          />
        </Box>
      )}

      <Box className={classes.fab}>
        <Slide
          direction="down"
          in={checkedProducts.length > 0}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Grid item>
            <Tooltip
              placement="left"
              title={<Typography align="center">Editar produtos selecionados</Typography>}
            >
              <Box position="relative">
                <Fab onClick={handleOpenDialogProcessProducts} color="primary">
                  <DoneAllIcon />
                  <Box className={classes.boxCheckedQuantity}>
                    <Typography color="black" marginTop="4px">
                      {checkedProducts.length}
                    </Typography>
                  </Box>
                </Fab>
              </Box>
            </Tooltip>
            {openDialogProcessProducts ? (
              <MassProcessProductsDialog
                handleCloseDialogProcessProducts={handleCloseDialogProcessProducts}
                openDialogProcessProducts={openDialogProcessProducts}
                checkedProducts={checkedProducts}
                handleRefreshAll={handleRefreshAll}
              />
            ) : null}
          </Grid>
        </Slide>
      </Box>
      <FabGroupButtons
        fabButtons={[
          {
            title: 'Atualizar listagem',
            onClick: handleRefreshAll,
            icon: <RefreshIcon />,
          },
        ]}
      />
    </Box>
  );
}

export default memo(Products);
