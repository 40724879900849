import { Button, ButtonGroup, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import checkValidCpfCnpj from '../../../../helpers/checkValidCpfCnpj';
import { formatCpfCpnj } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import AddressCard from '../../../Users/Details/AddressCard';
import AddressDialog from '../../../Users/Details/AddressDialog';

function AddCompanyDialog({
  companyForm,
  setCompanyForm,
  openAddCompanyDialog,
  handleCloseAddCompanyDialog,
  handleConfirmAddCompanyDialog,
  disableConfirmAddCompanyDialog,
}) {
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const handleOpenAddressDialog = () => setOpenAddressDialog(true);
  const handleCloseAddressDialog = () => setOpenAddressDialog(false);

  const handleAddAddress = (fields) => {
    setCompanyForm((oldFields) => ({
      ...oldFields,
      address: {
        ...oldFields.address,
        ...fields.address,
      },
    }));
  };

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setCompanyForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      openDialog={openAddCompanyDialog}
      handleClose={handleCloseAddCompanyDialog}
      dialogTitle="Adicionar uma empresa"
      dialogText="Adicione uma empresa para começar a utilizar o sistema"
      cancelButtonText="Cancelar"
      confirmButtonText="Continuar"
      handleCancelButton={handleCloseAddCompanyDialog}
      handleConfirmButton={handleConfirmAddCompanyDialog}
      content={
        <Grid container justifyContent="space-between" direction="column" spacing={2}>
          <Grid item>
            <Tooltip title={<Typography align="center">Situação</Typography>}>
              <ButtonGroup variant="contained" size="small">
                <Button
                  onClick={() =>
                    setCompanyForm((oldFields) => ({
                      ...oldFields,
                      active: true,
                    }))
                  }
                  color={companyForm.active ? 'primary' : 'default'}
                >
                  Ativa
                </Button>
                <Button
                  onClick={() =>
                    setCompanyForm((oldFields) => ({
                      ...oldFields,
                      active: false,
                    }))
                  }
                  color={companyForm.active ? 'default' : 'primary'}
                >
                  Inativa
                </Button>
              </ButtonGroup>
            </Tooltip>
          </Grid>
          <Grid item>
            <CustomInput
              label="Nome"
              name="name"
              value={companyForm.name}
              error={!companyForm.name}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="CNPJ"
              name="cnpj"
              value={formatCpfCpnj(companyForm.cnpj)}
              error={!checkValidCpfCnpj(companyForm.cnpj)}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Razão Social"
              name="razaoSocial"
              value={companyForm.razaoSocial}
              error={!companyForm.razaoSocial}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Nome Fantasia"
              name="nomeFantasia"
              value={companyForm.nomeFantasia}
              error={!companyForm.nomeFantasia}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Tipo"
              name="type"
              value={companyForm.type}
              error={!companyForm.type}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">Endereço</Typography>
          </Grid>
          {companyForm.address && companyForm.address.postalCode ? (
            <Grid item>
              <AddressCard
                address={companyForm.address}
                addressIndex={0}
                handleDeleteAddress={handleOpenAddressDialog}
                buttonType="edit"
              />
            </Grid>
          ) : (
            <Grid item onClick={handleOpenAddressDialog}>
              <CustomInput
                label="Endereço"
                name="address"
                value="Informe o endereço"
                variant="outlined"
              />
            </Grid>
          )}
          {openAddressDialog ? (
            <AddressDialog
              editAddress={
                companyForm.address && companyForm.address.postalCode ? companyForm.address : null
              }
              openDialogAddress={openAddressDialog}
              handleCloseDialogAddress={handleCloseAddressDialog}
              handleAddAddress={handleAddAddress}
            />
          ) : null}
        </Grid>
      }
      disableConfirmButton={disableConfirmAddCompanyDialog}
    />
  );
}

export default AddCompanyDialog;
