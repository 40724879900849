import Axios from 'axios';
import React, { memo, useEffect, useState } from 'react';

const shimmerSvg = (width, height) => `
<svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#d7d7d7" offset="00%" />
      <stop stop-color="#fff" offset="50%" />
      <stop stop-color="#d7d7d7" offset="90%" />
    </linearGradient>
  </defs>
  <rect width="${width}" height="${height}" fill="#d7d7d7" />
  <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${width}" to="${width}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const stringToBase64 = (string) =>
  typeof window === 'undefined' ? Buffer.from(string).toString('base64') : window.btoa(string);

function SimpleImage({ src, width, height, alt, className, imgWidth, imgHeight, style }) {
  const shimmerEffectSrc =
    width || height
      ? `data:image/svg+xml;base64,${stringToBase64(shimmerSvg(width || height, height || width))}`
      : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC';

  const [usedSrc, setUsedSrc] = useState(shimmerEffectSrc);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (src) {
          if (src.substring(0, 1) === '/') {
            setUsedSrc(src);
          } else {
            const imgUrl = new URL(src);
            const baseUrl = new URL(process.env.REACT_APP_REST_URL_IMG);

            if (imgUrl.host === baseUrl.host) {
              const parts = imgUrl.pathname.split('.');
              const extension = parts[parts.length - 1];

              if (extension !== 'svg') {
                if (width) imgUrl.searchParams.append('w', width);
                if (height) imgUrl.searchParams.append('h', height);
                imgUrl.searchParams.append('q', '100');
              }

              const response = await Axios({
                url: imgUrl.href,
                method: 'GET',
                responseType: extension === 'svg' ? 'text' : 'blob',
              });

              if (extension === 'svg') {
                setUsedSrc(`data:image/svg+xml;base64,${stringToBase64(response.data)}`);
              } else {
                setUsedSrc(URL.createObjectURL(response.data));
              }
            } else {
              setUsedSrc(imgUrl.href);
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <img
      src={usedSrc}
      alt={alt}
      className={className}
      width={imgWidth}
      height={imgHeight}
      style={style}
    />
  );
}

export default memo(SimpleImage);
