import React, { memo, useEffect } from 'react';
import { Box, Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchField from '../Common/SearchField';
import FilterSelectOptions from '../Common/FilterSelectOptions';
import CompanyAutocomplete from '../Common/CompanyAutocomplete';
import FilterOrdenateOptions from '../Common/FilterOrdenateOptions';
import FilterCategoriesOptions from '../Common/FilterCategoriesOptions';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBarProducts({
  submitSearchProducts,
  filterByCompany,
  setFilterByCompany,
  filtersStatus,
  setFiltersStatus,
  filtersOrdenateOptions,
  setFiltersOrdenateOptions,
  setFiltersByCategories,
  filterBySupplier,
  setPage,
}) {
  const classes = useStyles();

  useEffect(() => {
    setPage(1);
  }, [filterByCompany, filtersStatus, filterBySupplier]);

  return (
    <Toolbar>
      <Grid container direction="row" spacing={1} marginTop={1}>
        <Grid item xs={1}>
          <Typography variant="h5" className={classes.topBarTitle}>
            Produtos
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Box width={200}>
                <CompanyAutocomplete
                  handleOnChange={(_, newValue) => setFilterByCompany(newValue)}
                  selectedCompany={filterByCompany}
                  setSelectedCompany={setFilterByCompany}
                  variant="filled"
                  className={classes.selectBackground}
                />
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Status"
                filtersOptions={filtersStatus}
                setFiltersOptions={setFiltersStatus}
              />
            </Grid>
            <Grid item>
              <FilterOrdenateOptions
                inputLabel="Ordenar por"
                filtersOrdenateOptions={filtersOrdenateOptions}
                setFiltersOrdenateOptions={setFiltersOrdenateOptions}
              />
            </Grid>
            <Grid item>
              <FilterCategoriesOptions
                inputLabel="Famílias de Produtos"
                handleChangeCategoriesFilter={setFiltersByCategories}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar produto" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarProducts);
