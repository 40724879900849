const orderStatusColor = (orderStatus) => {
  let color = '#000000';

  switch (orderStatus) {
    case 'Orçamento':
      color = '#616160';
      break;
    case 'Pedido de Venda':
      color = '#F49B50';
      break;
    case 'Acerto Financeiro':
      color = '#2F6497';
      break;
    case 'Separar Estoque':
      color = '#5B7859';
      break;
    case 'Faturar':
      color = '#A84042';
      break;
    case 'Faturado':
      color = '#F49B50';
      break;
    case 'Entrega':
      color = '#2F6497';
      break;
    case 'Entregue':
      color = '#5B7859';
      break;
    default:
      color = '#000000';
      break;
  }

  return color;
};

export default orderStatusColor;
