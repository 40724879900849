import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useAuth } from './contexts/auth';
import Products from './components/Products';
import Orders from './components/Orders';
import UsersPage from './components/Users/Page';
import Settings from './components/Settings';
import Login from './components/Login';
import Header from './components/Header';
import NotFoundRoute from './components/Common/NotFoundRoute';
import RecoveryPassword from './components/RecoverPassword';
import Reports from './components/Reports';

function Pages() {
  const { signed } = useAuth();

  return (
    <BrowserRouter>
      <Box>
        {signed ? (
          <>
            <Header />
            <Container>
              <Routes>
                <Route path="*" element={<NotFoundRoute />} />
                <Route path="/pedidos" element={<Orders />} />
                <Route path="/produtos" element={<Products />} />
                <Route path="/usuarios" element={<UsersPage />} />
                <Route path="/configuracoes" element={<Settings />} />
                <Route path="/relatorios" element={<Reports />} />
              </Routes>
            </Container>
          </>
        ) : (
          <Routes>
            <Route path="*" element={<NotFoundRoute />} />
            <Route path="/" element={<Login />} />
            <Route path="/recuperar-senha/:resetPasswordToken" element={<RecoveryPassword />} />
          </Routes>
        )}
      </Box>
    </BrowserRouter>
  );
}

export default Pages;
