import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { formatDate } from '../../../../../../../../helpers/formatData';

const styles = StyleSheet.create({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  logoContainer: {
    width: '76%',
    border: 1,
    borderRadius: 3,
    padding: 5,
    marginRight: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  imageContainer: {
    width: '25%',
  },
  logo: {
    width: 80,
  },
  companyContainer: {
    width: '75%',
  },
  detailsContainer: {
    width: '24%',
    border: 1,
    borderRadius: 3,
    padding: 5,
  },
  orderIdContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  orderDateContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 5,
  },
  boldText: {
    fontWeight: 'bold',
  },
  smallText: {
    fontSize: 8,
  },
});

function Title({ selectedCompany, order }) {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.logoContainer}>
        <View style={styles.imageContainer}>
          <Image style={styles.logo} src="/assets/imgs/mark-ferragens.png" />
        </View>
        <View style={styles.companyContainer}>
          <Text style={styles.boldText}>Resumo do Pedido</Text>
          {selectedCompany?.address ? (
            <Text style={styles.smallText}>
              {selectedCompany.address.street}, {selectedCompany.address.streetNumber},{' '}
              {selectedCompany.address.district}, {selectedCompany.address.city}/
              {selectedCompany.address.state}, {selectedCompany.address.postalCode}
            </Text>
          ) : null}
          <Text style={styles.smallText}>www.kione.com.br</Text>
        </View>
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.orderIdContainer}>
          <Text style={styles.boldText}>Pedido: </Text>
          <Text style={styles.boldText}>{order.orderId}</Text>
        </View>
        <View style={styles.orderDateContainer}>
          <Text style={styles.boldText}>Data: </Text>
          <Text style={styles.boldText}>{formatDate(order.createdAt)}</Text>
        </View>
      </View>
    </View>
  );
}

export default Title;
