import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const useStyles = makeStyles({
  recoveryButtom: {
    width: 300,
    height: 48,
  },
});

function RecoveryPassword() {
  const { resetPasswordToken } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();
  const [fields, setFields] = useState({
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginPanel, setShowLoginPanel] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  useEffect(() => {
    setShowLoginPanel(true);
  }, []);

  const handleChangeFieldValue = (event) => {
    setFields((oldState) => ({
      ...oldState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeShowPassword = () => setShowPassword(!showPassword);

  const submitRecoveryPassword = async () => {
    if (!fields.password || !fields.confirmPassword) {
      return setSnackbar({
        open: true,
        message: 'Digite a senha e a confirmação da senha',
        type: 'error',
      });
    }

    if (fields.password !== fields.confirmPassword) {
      return setSnackbar({
        open: true,
        message: 'As senhas não coincidem',
        type: 'error',
      });
    }

    try {
      const response = await Axios.patch(`/users/reset-password/${resetPasswordToken}`, {
        password: fields.password,
      });

      setSnackbar({
        open: true,
        message: response.data.message,
        type: 'success',
      });

      setFields({
        password: '',
        confirmPassword: '',
      });

      return setTimeout(() => {
        navigate('/', { replace: true });
      }, 1500);
    } catch (error) {
      setFields({
        password: '',
        confirmPassword: '',
      });

      return formatHandleError({
        error,
        setSnackbar,
        defaultMessage: 'Erro ao recuperar senha',
      });
    }
  };

  const submitRecoveryPasswordOnEnter = async (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      submitRecoveryPassword();
    }
  };

  return (
    <>
      <Box
        height="100vh"
        width="100vw"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
        bgcolor="#5b7859"
      >
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Zoom in={showLoginPanel} timeout={1000}>
                <Grid container justifyContent="center" alignItems="center">
                  <Box marginBottom={1}>
                    <img alt="Logo Kione" src="/assets/imgs/logo-kione.png" width="210px" />
                  </Box>
                </Grid>
              </Zoom>
            </Grid>

            <Grid item>
              <Zoom in={showLoginPanel} timeout={1000}>
                <Grid container direction="column" alignItems="center" rowGap={1}>
                  <Grid item>
                    <Paper elevation={8}>
                      <Box width="340px" padding={2}>
                        <Grid container justifyContent="center" alignItems="center">
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography variant="h2" align="center">
                                  Altere sua senha
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Box width="300px" onKeyDown={submitRecoveryPasswordOnEnter}>
                                  <TextField
                                    fullWidth
                                    label="Senha"
                                    variant="filled"
                                    size="small"
                                    type={showPassword ? 'text' : 'password'}
                                    value={fields.password}
                                    name="password"
                                    onChange={handleChangeFieldValue}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleChangeShowPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box width="300px" onKeyDown={submitRecoveryPasswordOnEnter}>
                                  <TextField
                                    fullWidth
                                    label="Confirme a senha"
                                    variant="filled"
                                    size="small"
                                    type={showPassword ? 'text' : 'password'}
                                    value={fields.confirmPassword}
                                    name="confirmPassword"
                                    onChange={handleChangeFieldValue}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleChangeShowPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.recoveryButtom}
                                  onClick={submitRecoveryPassword}
                                >
                                  ALTERAR SENHA
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      fontWeight={800}
                      color="#fff"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/')}
                    >
                      VOLTAR À TELA DE LOGIN
                    </Typography>
                  </Grid>
                </Grid>
              </Zoom>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container alignItems="center" bottom={0} position="absolute" flexDirection="column">
        <Grid item>
          <Typography fontWeight={400} color="#fff">
            Desenvolvido por
          </Typography>
        </Grid>
        <Grid item marginTop={-0.5}>
          <img alt="Logo Kione" src="/assets/imgs/maishub-branco.png" width={100} />
        </Grid>
      </Grid>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default RecoveryPassword;
