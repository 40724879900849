import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import { blue } from '@mui/material/colors';
import {
  cleanUpDuplicateSpaces,
  formatCpfCpnj,
  formatHandleError,
  formatPhone,
  onlyNumbersValue,
} from '../../../helpers/formatData';
import TagsButtonGroup from '../../Common/TagsButtonGroup';
import CustomInput from '../../CustomInput';
import AddressCard from './AddressCard';
import checkValidCpfCnpj from '../../../helpers/checkValidCpfCnpj';
import SimpleDialog from '../../Common/SimpleDialog';
import AddressDialog from './AddressDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import PhoneDialog from './PhoneDialog';
import DrawerSubmitArea from '../../Common/DrawerSubmitArea/DrawerSubmitArea';
import { createUser, getUser, updateUser } from '../../../services/melhorGestao/users';
import getModifiedKeys from '../../../helpers/getModifiedKeys';
import CustomDivider from '../../CustomDivider';
import simulateMongoObjectId from '../../../helpers/simulateMongoObjectId';
import UserAutocomplete from '../../Common/UserAutocomplete';
import validateEmail from '../../../helpers/validateEmail';
import UserStaffDialog from './UserStaffDialog';
import TagsAutocomplete from '../../Common/TagsAutocomplete';
import CustomDatePicker from '../../CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('xs')]: {
      width: 365,
    },
    width: 620,
    marginBottom: 120,
  },
  chipPhones: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  inputAdornment: {
    marginBottom: 4,
  },
  changePasswordButton: {
    color: blue[500],
    cursor: 'pointer',
  },
}));

function UsersDetails({
  openDialogDetail,
  handleCloseDialogDetail,
  selectedUser,
  refreshOnSave = () => {},
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [initialUserForm, setInitialUserForm] = useState(selectedUser);
  const [userForm, setUserForm] = useState(selectedUser);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [openDialogStaff, setOpenDialogStaff] = useState(false);

  useEffect(() => {
    if (getModifiedKeys(userForm, initialUserForm).length > 0) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [userForm, initialUserForm]);

  const handleSwitchRoles = ({ roleFieldName }) => {
    setUserForm((oldValues) => ({
      ...oldValues,
      [roleFieldName]: !oldValues[roleFieldName],
    }));
  };

  const userRoles = [
    {
      value: userForm.customer,
      label: 'Cliente',
      onClick: () => handleSwitchRoles({ roleFieldName: 'customer' }),
    },
    {
      value: userForm.supplier,
      label: 'Fornecedor',
      onClick: () => handleSwitchRoles({ roleFieldName: 'supplier' }),
    },
    {
      value: userForm.exporter,
      label: 'Exportador',
      onClick: () => handleSwitchRoles({ roleFieldName: 'exporter' }),
    },
    {
      value: userForm.shippingCompany,
      label: 'Transportadora',
      onClick: () => handleSwitchRoles({ roleFieldName: 'shippingCompany' }),
    },
    {
      value: userForm.staff,
      label: 'Colaborador',
      onClick: () => handleSwitchRoles({ roleFieldName: 'staff' }),
    },
  ];

  const [seller, setSeller] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (userForm.othersIds?.sellerUserId) {
        const users = await getUser(userForm.othersIds?.sellerUserId);
        if (users && users.userId) {
          setSeller(users);
        }
      }
    };
    fetchData();
  }, []);

  const handleChangeSeller = (_, newValue) => {
    if (newValue && newValue.userId) {
      setSeller(newValue);

      setUserForm((oldFields) => ({
        ...oldFields,
        othersIds: {
          ...oldFields.othersIds,
          sellerUserId: newValue.userId,
        },
      }));
    } else {
      setSeller(null);

      setUserForm((oldFields) => ({
        ...oldFields,
        othersIds: {
          ...oldFields.othersIds,
          sellerUserId: null,
        },
      }));
    }
  };

  const handleChangeDocuments = (event) => {
    let fieldValue = event.target.value;
    const fieldType = event.target.type;
    const fieldName = event.target.name;

    if (fieldType === 'checkbox') {
      if (fieldValue === 'false') {
        fieldValue = true;
      } else {
        fieldValue = false;
      }
    }

    setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        [fieldName]: fieldValue,
      },
    }));
  };

  const handleChangeBirthday = (date) => {
    setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        birthday: date,
      },
    }));
  };

  const handleChange = (event) => {
    let fieldValue = event.target.value;
    const fieldType = event.target.type;
    const fieldName = event.target.name;

    if (fieldType === 'checkbox') {
      if (fieldValue === 'false') {
        fieldValue = true;
      } else {
        fieldValue = false;
      }
    }

    setUserForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const handleSwitchType = ({ type }) => {
    setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        pessoa: type,
      },
    }));
  };

  const handleChangeTags = (_, value) => {
    setUserForm((oldFields) => ({
      ...oldFields,
      tags: value,
    }));
  };

  const handleGetCnpj = useCallback(async () => {
    try {
      const cnpjNumbers = onlyNumbersValue(userForm.documents?.cnpj || '');
      if (cnpjNumbers.length === 14) {
        if (checkValidCpfCnpj(userForm.documents.cnpj)) {
          setLoadingCompanyData(true);
          const response = await Axios.get(
            `/users/receitaws/${onlyNumbersValue(userForm.documents.cnpj)}`,
          );
          const { companyData } = response.data;

          const address = {
            street: companyData.logradouro,
            streetNumber: companyData.numero,
            district: companyData.bairro,
            postalCode: onlyNumbersValue(companyData.cep),
            complement: companyData.complemento,
            city: companyData.municipio,
            state: companyData.uf,
          };

          setUserForm((oldFields) => ({
            ...oldFields,
            name: companyData.fantasia === '' ? companyData.nome : companyData.fantasia,
            phones: [
              ...oldFields.phones,
              {
                number: companyData.telefone,
              },
            ],
            documents: {
              ...oldFields.documents,
              razaoSocial: companyData.nome,
            },
            addresses: [
              ...oldFields.addresses,
              {
                ...address,
              },
            ],
          }));
          setLoadingCompanyData(false);
        } else {
          setSnackbar({
            message: 'O CNPJ inserido é inválido.',
            open: true,
            type: 'error',
          });
        }
      } else {
        setSnackbar({
          message: 'Digite um CNPJ válido.',
          open: true,
          type: 'error',
        });
      }
    } catch (error) {
      setLoadingCompanyData(false);
      console.log(error);
      setSnackbar({
        message: 'Não conseguimos puxar os dados. Aguarde um minuto para tentar novamente.',
        open: true,
        type: 'error',
      });
    }
  }, [userForm.documents]);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleDeletePhone = useCallback(
    ({ deleteIndex }) => {
      const updatedPhones = userForm.phones.filter((phone, index) => index !== deleteIndex);
      return setUserForm((oldFields) => ({
        ...oldFields,
        phones: updatedPhones,
      }));
    },
    [userForm.phones],
  );

  const handleDeleteAddress = useCallback(
    ({ deleteIndex }) => {
      const updatedAddresses = userForm.addresses.filter((address, index) => index !== deleteIndex);
      return setUserForm((oldFields) => ({
        ...oldFields,
        addresses: updatedAddresses,
      }));
    },
    [userForm.addresses],
  );

  const [openDialogAddress, setOpenDialogAddress] = useState(false);
  const handleOpenDialogAddress = () => setOpenDialogAddress(true);
  const handleCloseDialogAddress = () => setOpenDialogAddress(false);

  const handleAddAddress = ({ address }) =>
    setUserForm((oldFields) => ({
      ...oldFields,
      addresses: [...oldFields.addresses, { ...address }],
    }));

  const [openDialogPhone, setOpenDialogPhone] = useState(false);
  const handleOpenDialogPhone = () => setOpenDialogPhone(true);
  const handleCloseDialogPhone = () => setOpenDialogPhone(false);

  const handleAddPhone = ({ phone }) =>
    setUserForm((oldFields) => ({
      ...oldFields,
      phones: [...oldFields.phones, { ...phone }],
    }));

  const [loadingSaveUser, setLoadingSaveUser] = useState(false);

  const handleSaveForm = async ({ updateFields = {} }) => {
    try {
      if (userForm.addresses.length === 0) {
        return setSnackbar({
          message: 'Adicione pelo menos um endereço para o usuário',
          open: true,
          type: 'error',
        });
      }

      if (userForm.phones.length === 0) {
        return setSnackbar({
          message: 'Adicione pelo menos um número de telefone para o usuário',
          open: true,
          type: 'error',
        });
      }

      setFormChanged(false);
      setLoadingSaveUser(true);

      const formatedUser = {
        ...userForm,
        ...updateFields,
        name: userForm.name ? cleanUpDuplicateSpaces(userForm.name).toUpperCase() : '',
        email: userForm.email ? userForm.email.trim().toLowerCase() : '',
        documents: {
          ...userForm.documents,
          razaoSocial: userForm.documents?.razaoSocial
            ? cleanUpDuplicateSpaces(userForm.documents.razaoSocial).toUpperCase()
            : '',
          cpf: userForm.documents?.cpf ? onlyNumbersValue(userForm.documents.cpf) : '',
          cnpj: userForm.documents?.cnpj ? onlyNumbersValue(userForm.documents.cnpj) : '',
          inscricaoEstadual: userForm.documents?.inscricaoEstadual
            ? onlyNumbersValue(userForm.documents.inscricaoEstadual)
            : '',
        },
      };

      if (userForm.userId) {
        const updatedUser = await updateUser({ user: formatedUser, setSnackbar });
        if (updatedUser) {
          setUserForm(updatedUser);
          setInitialUserForm(updatedUser);
        }
      } else {
        const createdUser = await createUser({ user: formatedUser, setSnackbar });
        if (createdUser) {
          setUserForm(createdUser);
          setInitialUserForm(createdUser);
        }
      }

      setLoadingSaveUser(false);
      return refreshOnSave();
    } catch (error) {
      setLoadingSaveUser(false);
      return setSnackbar({
        message: error?.response?.data?.message || 'Algum erro ocorreu ao tentar salvar o usuário',
        open: true,
        type: 'error',
      });
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleCheckCloseDetail = () => {
    if (!formChanged) {
      return handleCloseDialogDetail();
    }
    return setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => setConfirmDialogOpen(false);

  const handleRecoveryPassword = async () => {
    if (!validateEmail(userForm.email)) {
      return setSnackbar({
        open: true,
        message: 'Digite um e-mail válido para recuperar a senha',
        type: 'error',
      });
    }

    try {
      const response = await Axios.post('/users/reset-password', {
        source: 'Kione',
        email: userForm.email,
      });

      return setSnackbar({
        open: true,
        message: response.data.message,
        type: 'info',
      });
    } catch (error) {
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Erro ao enviar link de redefinição de senha',
        error,
      });
    }
  };

  return (
    <Drawer
      sx={{
        zIndex: 1300,
      }}
      anchor="right"
      open={openDialogDetail}
      onClose={handleCheckCloseDetail}
    >
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={12} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Chip
                  color="primary"
                  label={selectedUser.userId ? `COD: ${selectedUser.userId}` : 'Novo usuário'}
                />
              </Grid>
              <Grid item>
                <Tooltip title={<Typography align="center">Tipo de pessoa</Typography>}>
                  <ButtonGroup variant="contained" size="small">
                    <Button
                      onClick={() => handleSwitchType({ type: 'PF' })}
                      color={userForm.documents?.pessoa === 'PF' ? 'primary' : 'default'}
                    >
                      Física
                    </Button>
                    <Button
                      onClick={() => handleSwitchType({ type: 'PJ' })}
                      color={userForm.documents?.pessoa === 'PJ' ? 'primary' : 'default'}
                    >
                      Jurídica
                    </Button>
                  </ButtonGroup>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container alignItems="center" justifyContent="center" spacing={1}>
              <Grid item>
                <TagsButtonGroup chipButtons={userRoles} />
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Dados básicos</Typography>
              </Grid>
              {userForm.staff && !formChanged ? (
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="caption"
                        className={classes.changePasswordButton}
                        onClick={handleRecoveryPassword}
                      >
                        Enviar link de alteração de senha
                      </Typography>
                    </Grid>
                    <Grid item>
                      {userForm.staff && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setOpenDialogStaff(true)}
                        >
                          Permissões
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CustomInput
                  onChange={handleChange}
                  name="name"
                  value={userForm.name}
                  label={userForm.documents?.pessoa === 'PJ' ? 'Nome Fantasia' : 'Nome Abreviado'}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  onChange={handleChange}
                  name="email"
                  value={userForm.email}
                  label="E-mail"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomInput
              onChange={handleChangeDocuments}
              name="razaoSocial"
              value={userForm.documents?.razaoSocial || ''}
              label={userForm.documents?.pessoa === 'PJ' ? 'Razão Social' : 'Nome Completo'}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item xs={6}>
                <Grid container wrap="nowrap" alignItems="flex-end" justifyContent="space-between">
                  <Grid item xs={9}>
                    {userForm.documents?.pessoa === 'PJ' ? (
                      <CustomInput
                        onChange={handleChangeDocuments}
                        name="cnpj"
                        value={formatCpfCpnj(userForm.documents?.cnpj || '')}
                        label="CNPJ"
                        length="18"
                      />
                    ) : (
                      <CustomInput
                        onChange={handleChangeDocuments}
                        name="cpf"
                        value={formatCpfCpnj(userForm.documents.cpf || '')}
                        label="CPF"
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleGetCnpj}
                      disabled={userForm.documents?.pessoa === 'PF'}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  onChange={handleChangeDocuments}
                  name="inscricaoEstadual"
                  value={userForm.documents?.inscricaoEstadual || ''}
                  label="Inscrição Estadual"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <UserAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleChangeSeller}
                  selectedUser={seller}
                  label="Vendedor responsável"
                  variant="filled"
                  roles={['staff']}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomDatePicker
                  size="small"
                  label="Aniversário"
                  format="DD/MM/YYYY"
                  variant="filled"
                  value={userForm.documents?.birthday || null}
                  onChange={handleChangeBirthday}
                />
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Telefones</Typography>
              </Grid>
              {userForm.phones.length < 2 ? (
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleOpenDialogPhone}>
                    Adicionar
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {userForm.phones && userForm.phones.length > 0 ? (
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-start">
                {userForm.phones
                  ? userForm.phones.map((phone, index) => (
                      <Grid item key={JSON.stringify(phone)}>
                        <Chip
                          className={classes.chipPhones}
                          size="small"
                          label={
                            <Grid container justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <Typography>{formatPhone(phone.number)}</Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeletePhone({ deleteIndex: index })}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          ) : null}
          <CustomDivider />
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Endereços</Typography>
              </Grid>
              {userForm.addresses.length === 0 ? (
                <Grid item>
                  <Button onClick={handleOpenDialogAddress} variant="contained" color="primary">
                    Adicionar
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {userForm.addresses?.length > 0 ? (
            <Grid item>
              <Grid container>
                {userForm.addresses.map((address, index) => (
                  <AddressCard
                    key={address._id || simulateMongoObjectId()}
                    address={address}
                    addressIndex={index}
                    handleDeleteAddress={handleDeleteAddress}
                  />
                ))}
              </Grid>
            </Grid>
          ) : null}

          {userForm.customer ? (
            <>
              <CustomDivider />
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">Perfil do cliente</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TagsAutocomplete
                  type="users"
                  handleOnChange={handleChangeTags}
                  selectedValue={userForm.tags || []}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>

      <DrawerSubmitArea
        handleClose={handleCheckCloseDetail}
        handleConfirm={handleSaveForm}
        formChanged={formChanged}
      />

      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}

      <SimpleDialog
        openDialog={confirmDialogOpen}
        handleClose={handleCloseConfirmDialog}
        content={<SimpleBackdrop loading={loadingSaveUser} />}
        dialogTitle="Você não salvou as alterações"
        dialogText="Você irá perder todas as alterações que realizou caso deseje sair"
        cancelButtonText="Sair sem salvar"
        confirmButtonText="Salvar alterações"
        handleCancelButton={handleCloseDialogDetail}
        handleConfirmButton={() => {
          handleCloseDialogDetail();
          handleSaveForm({});
        }}
      />
      <UserStaffDialog
        openDialogStaff={openDialogStaff}
        handleCloseDialogStaff={() => setOpenDialogStaff(false)}
        userForm={userForm}
      />
      <AddressDialog
        openDialogAddress={openDialogAddress}
        handleCloseDialogAddress={handleCloseDialogAddress}
        handleAddAddress={handleAddAddress}
      />
      <PhoneDialog
        openDialogPhone={openDialogPhone}
        handleCloseDialogPhone={handleCloseDialogPhone}
        handleAddPhone={handleAddPhone}
      />
      <SimpleBackdrop loading={loadingCompanyData} />
    </Drawer>
  );
}

export default memo(UsersDetails);
