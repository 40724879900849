import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Axios from 'axios';
import { formatHandleError } from '../../../helpers/formatData';

function OmieConfigAutocomplete({
  companyId,
  routeName, // contacorrente, cenarios, vendedores, categorias, tabelaprecos, omieactions, omieactionsconfig
  handleOnChange = () => {},
  selectedValue,
  label,
  disabled,
  error,
  variant,
  className,
  setSnackbar,
}) {
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const getAutoCompleteValues = async () => {
      setLoading(true);
      try {
        const response = await Axios.get('/omie/config/omie-ids', {
          params: { companyId, routeName },
        });
        const omieIds = response.data;
        setAutocompleteValues(omieIds);

        if (typeof selectedValue === 'object') {
          setSelectedOption(selectedValue);
        } else {
          const option = omieIds.find((id) => id.id === selectedValue);

          if (option) {
            setSelectedOption(option);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar os ids na Omie',
          error: err,
        });
      }
    };

    if (companyId && routeName) {
      getAutoCompleteValues();
    }
  }, [selectedValue, companyId, routeName]);

  return (
    <Autocomplete
      loading={loading}
      size="small"
      options={autocompleteValues}
      getOptionLabel={(option) => option.description}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <Typography {...{ ...props }} key={option.id} variant="caption">
          {option.description}
        </Typography>
      )}
      disabled={disabled}
      value={selectedOption}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default OmieConfigAutocomplete;
