import Axios from 'axios';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import LoopIcon from '@mui/icons-material/Loop';
import OrderStatusTypography from '../../elements/OrderStatusTypography';
import UserAutocomplete from '../../../Common/UserAutocomplete';
import SimpleDialog from '../../../Common/SimpleDialog';
import { formatHandleError } from '../../../../helpers/formatData';
import StatusHistoryDialog from './StatusHistoryDialog';
import filterOrderSourceInitial from '../../../../constant/filterOrderSourceInitial';

function StatusTab({ orderForm, setOrderForm, setSeller, setSnackbar, closeOrderDetails }) {
  const handleChangeSeller = (_, newValue) =>
    setOrderForm((oldFields) => ({
      ...oldFields,
      seller: newValue,
    }));

  const [openDialogStatusHistory, setOpenDialogStatusHistory] = useState(false);
  const handleOpenDialogStatusHistory = () => setOpenDialogStatusHistory(true);
  const handleCloseDialogStatusHistory = () => setOpenDialogStatusHistory(false);

  const [loadingRepeatOrder, setLoadingRepeatOrder] = useState(false);
  const [openDialogRepeatOrder, setOpenDialogRepeatOrder] = useState(false);
  const handleOpenDialogRepeatOrder = () => setOpenDialogRepeatOrder(true);
  const handleCloseDialogRepeatOrder = () => setOpenDialogRepeatOrder(false);

  const handleConfirmRepeatOrder = async () => {
    try {
      if (orderForm.orderId) {
        setLoadingRepeatOrder(true);
        await Axios.post(`/orders/repeat-order/${orderForm.orderId}`);
        handleCloseDialogRepeatOrder();
        setLoadingRepeatOrder(false);
      }
      return closeOrderDetails();
    } catch (error) {
      setLoadingRepeatOrder(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao repetir o pedido',
        error,
      });
    }
  };

  const handleChangeSource = (source) =>
    setOrderForm((oldFields) => ({
      ...oldFields,
      source,
    }));

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={3} alignSelf="center">
        <Grid container direction="column">
          <OrderStatusTypography orderStatus={orderForm.status} useCustomStatusName />
          {orderForm.statusPending ? (
            <Typography variant="caption">{orderForm.statusPending}</Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={3.5} marginTop={0.5}>
        <Grid container>
          <Tooltip
            title={
              <Typography align="center">Histórico de alteração da situação do pedido</Typography>
            }
          >
            <Grid item>
              <IconButton
                onClick={handleOpenDialogStatusHistory}
                disabled={!orderForm.statusHistory || !orderForm.statusHistory.length > 0}
              >
                <HistoryIcon />
                <Typography variant="caption">Histórico</Typography>
              </IconButton>
            </Grid>
          </Tooltip>
          <Tooltip
            title={
              <Typography align="center">
                Cria um orçamento novo com os mesmo itens deste pedido.
              </Typography>
            }
          >
            <Grid item>
              <IconButton
                onClick={handleOpenDialogRepeatOrder}
                disabled={!orderForm.orderId || !orderForm.items || !orderForm.items.length < 0}
              >
                <LoopIcon />
                <Typography variant="caption">Repetir</Typography>
              </IconButton>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={5.5}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <UserAutocomplete
              setSnackbar={setSnackbar}
              handleOnChange={handleChangeSeller}
              selectedUser={orderForm.seller}
              setSelectedUser={setSeller}
              label="Vendedor"
              disabled={orderForm.status !== 'Orçamento'}
              roles={['staff']}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column">
              <FormControl fullWidth variant="filled" size="small">
                <InputLabel>Origem</InputLabel>
                <Select
                  onChange={(event) => handleChangeSource(event.target.value)}
                  value={orderForm.source}
                  error={!orderForm.source}
                  disabled={orderForm.status !== 'Orçamento'}
                >
                  {filterOrderSourceInitial.map((source) => (
                    <MenuItem value={source.name} key={source.name}>
                      {source.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openDialogStatusHistory ? (
        <StatusHistoryDialog
          orderForm={orderForm}
          openDialogStatusHistory={openDialogStatusHistory}
          handleCloseDialogStatusHistory={handleCloseDialogStatusHistory}
        />
      ) : null}
      {openDialogRepeatOrder ? (
        <SimpleDialog
          openDialog={openDialogRepeatOrder}
          handleClose={handleCloseDialogRepeatOrder}
          dialogTitle="Repetir pedido"
          dialogText="Deseja repetir este pedido? Será criado um novo orçamento com os mesmo itens deste pedido."
          cancelButtonText="Cancelar"
          content=""
          confirmButtonText="Confirmar"
          confirmButtonLoading={loadingRepeatOrder}
          maxWidth="xs"
          handleCancelButton={handleCloseDialogRepeatOrder}
          handleConfirmButton={handleConfirmRepeatOrder}
        />
      ) : null}
    </Grid>
  );
}

export default StatusTab;
