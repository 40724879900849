import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Grid, Snackbar } from '@mui/material';
import Axios from 'axios';
import SimpleDialog from '../../../../Common/SimpleDialog';
import SimpleBackdrop from '../../../../Common/SimpleBackdrop';
import OrderDetails from '../../../../Orders/Details';
import MovementTable from './Table';
import { formatHandleError } from '../../../../../helpers/formatData';
import FilterSelectOptions from '../../../../Common/FilterSelectOptions';

function DialogMovement({ openDialogMovement, product, handleCloseDialogMovement }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const initialFilters = [
    {
      name: 'Entrada',
      active: false,
    },
    {
      name: 'Saída',
      active: false,
    },
  ];

  const [filters, setFilters] = useState(initialFilters);
  const [stockMovement, setStockMovement] = useState([]);
  const [loadingStockMovement, setLoadingStockMovement] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loadingOrder, setLoadingOrder] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (product.productId) {
        try {
          setLoadingStockMovement(true);
          const response = await Axios.get(`/stock-movement/product/${product.productId}`);
          if (response.data && response.data.stockMovement) {
            let withFilter = false;
            const filteredStockMovement = [];

            response.data.stockMovement.forEach((movement) => {
              filters.forEach((filter) => {
                if (filter.active) {
                  withFilter = true;
                }
                if (filter.active && filter.name === movement.type) {
                  filteredStockMovement.push(movement);
                }
              });
            });

            if (withFilter) {
              setStockMovement(filteredStockMovement);
            } else {
              setStockMovement(response.data.stockMovement);
            }
          }
          setLoadingStockMovement(false);
        } catch (error) {
          setLoadingStockMovement(false);
          formatHandleError({
            setSnackbar,
            defaultMessage: 'Algum erro ocorreu ao buscar as movimentações',
            error,
          });
        }
      }
    };
    fetchData();
  }, [product.productId, filters]);

  const closeOrderDetails = useCallback(() => setOpenOrderDetails(false), []);

  const handleSelectOrder = async (entry) => {
    if (entry && entry.sourceId) {
      try {
        setLoadingOrder(true);

        const response = await Axios.get(
          `/${entry.sourceType === 'Entrada de produto' ? 'entries' : 'orders'}/${entry.sourceId}`,
        );

        const orderDetails = response.data.order || response.data.entryOrder;
        setSelectedOrder(orderDetails);

        setTimeout(() => {
          setLoadingOrder(false);
        }, 1000);
      } catch (error) {
        setLoadingOrder(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar o pedido',
          error,
        });
      }
    }
  };

  useEffect(() => {
    if (selectedOrder && selectedOrder.orderId) {
      setOpenOrderDetails(true);
    }
  }, [selectedOrder]);

  return (
    <SimpleDialog
      content={
        <Box width={600}>
          <SimpleBackdrop loading={loadingStockMovement} />
          <SimpleBackdrop loading={loadingOrder} />
          {!loadingStockMovement ? (
            <MovementTable handleSelectOrder={handleSelectOrder} stockMovement={stockMovement} />
          ) : null}
          {openOrderDetails && selectedOrder?.orderId && (
            <OrderDetails
              openOrderDetails={openOrderDetails}
              closeOrderDetails={closeOrderDetails}
              selectedOrder={selectedOrder}
            />
          )}
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      }
      dialogTitle={
        <Box width={540}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>Movimentação</Grid>
            <Grid item>
              <FilterSelectOptions
                filtersOptions={filters}
                setFiltersOptions={setFilters}
                autoConfirm
              />
            </Grid>
          </Grid>
        </Box>
      }
      dialogText={product.name}
      handleClose={handleCloseDialogMovement}
      openDialog={openDialogMovement}
    />
  );
}

export default DialogMovement;
