import Axios from 'axios';
import { Box, Paper } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import InputIcon from '@mui/icons-material/Input';
import RefreshIcon from '@mui/icons-material/Refresh';
import newUser from '../../../constant/newUser';
import { searchUsers } from '../../../services/melhorGestao/users';
import UsersTable from '../Table';
import UsersTopBar from '../TopBar';
import UsersDetails from '../Details';
import FabGroupButtons from '../../Common/FabGroupButtons';
import userRolesFiltersInitials from '../../../constant/userRolesFiltersInitials';
import DevelopedBy from '../../Common/DevelopedBy';

function UsersPage({
  sendToOrderFabButton,
  handleSelectCustomerToOrder,
  userRolesFilters = userRolesFiltersInitials,
  hiddenTitle,
}) {
  const [usersList, setUsersList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [selectedUserDetail, setSelectedUserDetail] = useState(newUser);
  const [refreshToken, setRefreshToken] = useState();
  const [rolesFilters, setRolesFilters] = useState(userRolesFilters);
  const [tagsOptions, setTagsOptions] = useState([]);

  const refreshSearch = useCallback(() => setRefreshToken(Math.random()), []);

  useEffect(() => {
    const fetchData = () => {
      searchUsers({
        limit,
        skip: page * limit - limit,
        searchText: searchTextSubmited,
        setLoadingUsersList,
        setUsersList,
        setTotalUsers,
        roles: rolesFilters.filter((option) => option.active).map((option) => option.role),
        tag: tagsOptions.filter((option) => option.active).map((option) => option.id),
      });
    };
    fetchData();
  }, [page, limit, searchTextSubmited, rolesFilters, tagsOptions, refreshToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await Axios.get('/mobile-admin/autocomplete', {
          params: {
            type: 'userTags',
          },
        });
        setTagsOptions(tags.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, usersList],
  );

  const handleOpenDialogDetail = useCallback(() => setOpenDialogDetail(true), []);
  const handleCloseDialogDetail = useCallback(() => setOpenDialogDetail(false), []);

  const handleClickNewUser = useCallback(() => {
    setSelectedUserDetail(newUser);
    return setOpenDialogDetail(true);
  }, []);

  const [fabButtons, setFabButtons] = useState([]);
  const [selectedCustomerToOrder, setSelectCustomerToOrder] = useState();

  const handleClickUser = useCallback(
    (user) => {
      if (sendToOrderFabButton) {
        return setSelectCustomerToOrder(user);
      }
      setSelectedUserDetail(user);
      return handleOpenDialogDetail();
    },
    [handleOpenDialogDetail, sendToOrderFabButton],
  );

  const handleSendUserToOrder = useCallback(() => {
    if (selectedCustomerToOrder) {
      return handleSelectCustomerToOrder({ user: selectedCustomerToOrder });
    }

    return null;
  }, [selectedCustomerToOrder]);

  useEffect(() => {
    if (sendToOrderFabButton && handleSelectCustomerToOrder) {
      setFabButtons([
        {
          title: 'Atualizar listagem',
          onClick: refreshSearch,
          icon: <RefreshIcon />,
        },
        {
          title: 'Novo cliente',
          onClick: handleClickNewUser,
          icon: <AddIcon />,
          featured: true,
        },
        {
          title: 'Enviar usuário selecionado para o pedido',
          onClick: handleSendUserToOrder,
          icon: <InputIcon />,
          featured: true,
        },
      ]);
    }
  }, [selectedCustomerToOrder]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Atualizar listagem',
        onClick: refreshSearch,
        icon: <RefreshIcon />,
      },
      {
        title: 'Novo cliente',
        onClick: handleClickNewUser,
        icon: <AddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <UsersTopBar
          submitSearch={setSearchTextSubmited}
          rolesFilters={rolesFilters}
          setRolesFilters={setRolesFilters}
          tagsOptions={tagsOptions}
          setTagsOptions={setTagsOptions}
          hiddenTitle={hiddenTitle}
        />
      </Box>
      <Box marginTop={2} marginBottom={1}>
        <Paper>
          <UsersTable
            usersList={usersList}
            totalUsers={totalUsers}
            selectedUser={selectedUserDetail}
            handleClickUser={handleClickUser}
            page={page}
            handleChangePage={handleChangePage}
            limit={limit}
            selectedUserInList={selectedCustomerToOrder}
            loadingUsersList={loadingUsersList}
          />
        </Paper>
        <DevelopedBy />
      </Box>

      <FabGroupButtons fabButtons={fabButtons} />

      {openDialogDetail && (
        <UsersDetails
          openDialogDetail={openDialogDetail}
          handleCloseDialogDetail={handleCloseDialogDetail}
          selectedUser={selectedUserDetail}
          refreshOnSave={refreshSearch}
        />
      )}
    </Box>
  );
}

export default memo(UsersPage);
