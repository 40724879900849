import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ShoppingCartCheckout } from '@mui/icons-material';
import TopBarReports from './TopBarReports';
import OrdersReports from './OrdersReports';

const menuItems = [
  {
    name: 'Pedidos',
    icon: ShoppingCartCheckout,
  },
];

function Reports() {
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].name);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <TopBarReports
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
        />
      </Box>
      {selectedMenuItem === 'Pedidos' ? <OrdersReports /> : null}
    </Box>
  );
}

export default Reports;
