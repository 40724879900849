import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, Button, Drawer, Grid, Snackbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import { createOrder, updateOrder as updatedOrder } from '../../../services/melhorGestao/orders';
import newOrder from '../../../constant/newOrder';
import { formatHandleError, numberToReal } from '../../../helpers/formatData';
import getModifiedKeys from '../../../helpers/getModifiedKeys';
import CustomDivider from '../../CustomDivider';
import TopTab from './TopTab';
import StatusTab from './StatusTab';
import ProductsTab from './ProductsTab';
import CustomerTab from './CustomerTab';
import ShippingTab from './ShippingTab';
import SubmitArea from './SubmitArea';
import { updateUser } from '../../../services/melhorGestao/users';
import SimpleDialog from '../../Common/SimpleDialog';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('xs')]: {
      width: 365,
    },
    width: 620,
    marginBottom: 120,
  },
}));

function OrderDetails({
  openOrderDetails,
  selectedOrder = newOrder,
  closeOrderDetails = () => {},
  submitSearchOrders = () => {},
  setFilterOrderStatus = () => {},
  setFilterOrderSource = () => {},
  setFilterOrderCompany = () => {},
  setSnackbarOutside = () => {},
}) {
  const classes = useStyles();

  const [initialOrderForm, setInitialOrderForm] = useState(selectedOrder);
  const [orderForm, setOrderForm] = useState(selectedOrder);
  const [loadingUpdateOrder, setLoadingUpdateOrder] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleSaveForm = async ({
    updateFields = {},
    customSaveMessage = '',
    dontShowMessage = false,
  }) => {
    try {
      if (orderForm.orderId) {
        const getUpdatedOrder = await updatedOrder({
          order: { ...orderForm, ...updateFields },
          setLoadingUpdateOrder,
          setSnackbar,
        });

        if (!getUpdatedOrder) {
          return null;
        }

        setOrderForm(getUpdatedOrder);
        setInitialOrderForm(getUpdatedOrder);
      } else {
        const newOrderResponse = await createOrder({
          order: { ...orderForm },
          setLoadingSaveOrder: setLoadingUpdateOrder,
          setSnackbar,
        });

        if (!newOrderResponse) {
          return null;
        }

        setOrderForm(newOrderResponse);
        setInitialOrderForm(newOrderResponse);
      }

      if (orderForm.customer?.userId) {
        await updateUser({
          user: {
            userId: orderForm.customer.userId,
            'documents.inscricaoEstadual': orderForm.customer?.documents?.inscricaoEstadual || '',
          },
          setSnackbar,
        });
      }

      if (!dontShowMessage) {
        setSnackbar({
          message: customSaveMessage || 'Pedido salvo com sucesso',
          open: true,
          type: 'success',
        });
      }

      return null;
    } catch (error) {
      console.log(error);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Por algum motivo as alterações do pedido não foram salvas',
        error,
      });
    }
  };

  useEffect(() => {
    const ignoredKeys = ['discountTotal', 'itemsTotal', 'orderTotal'];
    const keys = getModifiedKeys(orderForm, initialOrderForm, ignoredKeys);

    if (keys.length > 0) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [orderForm, initialOrderForm]);

  const confirmCloseProductDetails = () => {
    if (!formChanged) {
      return closeOrderDetails();
    }
    return setOrderForm(initialOrderForm);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [seller, setSeller] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [userItemsBought, setUserItemsBought] = useState([]);

  const totalItems = orderForm.items.reduce(
    (total, item) => total + item.selectedPrice * item.quantity,
    0,
  );

  const [openDialogConfirmDeleteOrder, setOpenDialogConfirmDeleteOrder] = useState(false);
  const [loadingDeleteOrder, setLoadingDeleteOrder] = useState(false);
  const handleOpenDialogConfirmDeleteOrder = () => {
    setOpenDialogConfirmDeleteOrder(true);
  };

  const handleCloseDialogConfirmDeleteOrder = () => {
    setOpenDialogConfirmDeleteOrder(false);
  };

  const handleConfirmDeleteOrder = async () => {
    try {
      setLoadingDeleteOrder(true);
      await Axios.delete(`/orders/${orderForm.orderId}`);
      setLoadingDeleteOrder(false);
      setSnackbarOutside({
        message: 'Pedido excluído com sucesso',
        type: 'success',
        open: true,
      });
      closeOrderDetails();
    } catch (error) {
      setLoadingDeleteOrder(false);
      formatHandleError({ defaultMessage: 'Erro ao excluir pedido', error, setSnackbar });
    }
  };

  return (
    <Drawer
      sx={{
        zIndex: 1300,
      }}
      anchor="right"
      open={openOrderDetails}
      onClose={confirmCloseProductDetails}
    >
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={12} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TopTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              setSelectedCompany={setSelectedCompany}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <StatusTab
                orderForm={orderForm}
                setOrderForm={setOrderForm}
                setSeller={setSeller}
                setSnackbar={setSnackbar}
                closeOrderDetails={closeOrderDetails}
              />
            </Box>
          </Grid>
          <CustomDivider />
          <Grid item>
            <ProductsTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              initialOrderForm={initialOrderForm}
              setInitialOrderForm={setInitialOrderForm}
              userItemsBought={userItemsBought}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <CustomerTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              selectedOrder={selectedOrder}
              userItemsBought={userItemsBought}
              setUserItemsBought={setUserItemsBought}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <ShippingTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              formChanged={formChanged}
              handleSaveForm={handleSaveForm}
              selectedOrderCompany={selectedCompany || orderForm.company}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <CustomDivider />
          {orderForm.items?.length > 0 && orderForm?.orderId ? (
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="flex-end">
                {orderForm.status === 'Orçamento' ? (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="error"
                      style={{ color: 'white' }}
                      onClick={handleOpenDialogConfirmDeleteOrder}
                    >
                      Excluir Pedido
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={8}>
                  <Grid container justifyContent="flex-end" spacing={1} alignItems="baseline">
                    <Grid item>
                      <Typography>Total dos produtos:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h3">R$ {numberToReal(totalItems)}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {orderForm?.shipping ? (
                      <Grid item xs={12}>
                        <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                          <Grid item>
                            <Typography>Frete:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h3">
                              {orderForm.shipping?.price
                                ? `R$ ${numberToReal(orderForm.shipping.price)}`
                                : 'Grátis'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <Grid item>
                          <Typography>Total do pedido:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h2">
                            R${' '}
                            {numberToReal(
                              orderForm.orderTotal ||
                                totalItems + (orderForm?.shipping?.price || 0),
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <SubmitArea
        orderForm={orderForm}
        setOrderForm={setOrderForm}
        setInitialOrderForm={setInitialOrderForm}
        formChanged={formChanged}
        selectedOrder={selectedOrder}
        selectedCompany={selectedCompany}
        seller={seller}
        handleSaveForm={handleSaveForm}
        loadingUpdateOrder={loadingUpdateOrder}
        confirmCloseProductDetails={confirmCloseProductDetails}
        closeOrderDetails={closeOrderDetails}
        submitSearchOrders={submitSearchOrders}
        setFilterOrderStatus={setFilterOrderStatus}
        setFilterOrderSource={setFilterOrderSource}
        setFilterOrderCompany={setFilterOrderCompany}
        setSnackbar={setSnackbar}
      />
      {openDialogConfirmDeleteOrder ? (
        <SimpleDialog
          openDialog={openDialogConfirmDeleteOrder}
          handleClose={handleCloseDialogConfirmDeleteOrder}
          dialogTitle="Excluir pedido"
          content={
            <>
              <Typography>
                O pedido será excluído no Mais Hub e, caso exista na Omie, será excluído também.
              </Typography>
              <Typography>
                Confirma a exclusão? <b>Esta ação não pode ser desfeita.</b>
              </Typography>
            </>
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogConfirmDeleteOrder}
          handleConfirmButton={handleConfirmDeleteOrder}
          confirmButtonLoading={loadingDeleteOrder}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Drawer>
  );
}

export default memo(OrderDetails);
