import {
  Box,
  Checkbox,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { formatDateWithHour, numberToRealWithPeriod } from '../../helpers/formatData';
import SimpleBackdrop from '../Common/SimpleBackdrop';
import OrderStatusTypography from './elements/OrderStatusTypography';
import { getOrder } from '../../services/melhorGestao/orders';
import InvoiceInquiryLink from '../Common/InvoiceInquiryLink';
import CustomerNameArea from './elements/CustomerNameArea';
import OrderCompanyLogo from './elements/OrderCompanyLogo';
import OrderSourceLogo from './elements/OrderSourceLogo';
import DevelopedBy from '../Common/DevelopedBy';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 25,
  },
  smallCell: {
    width: 6,
  },
  centerCellVertical: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '100%',
  },
  typographyCursor: {
    cursor: 'pointer',
  },
  closeIcon: {
    color: '#ddd',
  },
  sellerLogoGridMark: {
    height: 25,
    width: 130,
    borderRadius: 8,
    backgroundColor: '#000',
  },
  sellerLogoImgMark: {
    width: 120,
    margin: '0px 10px',
  },
  sellerLogoGridRm: {
    height: 25,
    width: 130,
    borderRadius: 8,
    backgroundColor: '#00007C',
  },
  sellerLogoImgRm: {
    height: 23,
    margin: '0px 10px',
  },
  headTableCheckbox: {
    width: 35,
  },
  headTableCellId: {
    width: 75,
  },
  headTableCellCompany: {
    width: 65,
  },
  headTableCellSource: {
    width: 65,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  companyBadge: {
    width: 35,
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  tableRowOccurrence: {
    backgroundColor: '#fff9e5',
    '&:hover': {
      backgroundColor: '#fff4cc',
    },
  },
}));

function TableOrders({
  ordersList,
  countOrders,
  selectOrder,
  selectedOrder,
  handleChangePage,
  page,
  limit,
  checkedOrders,
  setCheckedOrders,
  loadingOrdersList,
}) {
  const classes = useStyles();

  const getTableRowColor = (orderSelected, order) => {
    if (order.pendingOccurrences) {
      return classes.tableRowOccurrence;
    }

    return orderSelected && orderSelected.orderId === order.orderId
      ? classes.tableRowSelected
      : null;
  };

  const handleCheckOrder = ({ orderId }) => {
    let newCheckedOrders = checkedOrders;
    newCheckedOrders = newCheckedOrders.filter((checkedOrderId) => checkedOrderId !== orderId);
    newCheckedOrders.push(orderId);

    return setCheckedOrders(newCheckedOrders);
  };

  const handleUncheckOrder = ({ orderId }) => {
    let newCheckedOrders = checkedOrders;
    newCheckedOrders = newCheckedOrders.filter((checkedOrderId) => checkedOrderId !== orderId);

    return setCheckedOrders(newCheckedOrders);
  };

  const isCheckedOrder = ({ orderId }) => {
    if (checkedOrders.filter((checkedOrderId) => checkedOrderId === orderId).length > 0) {
      return true;
    }
    return false;
  };

  const handleUncheckAll = () => setCheckedOrders([]);

  const handleCheckAll = () => {
    const ordersIds = ordersList.map((order) => order.orderId);
    return setCheckedOrders(ordersIds);
  };

  const handleSelectOrder = async (orderId) => {
    const order = await getOrder(orderId);
    selectOrder(order);
  };

  return (
    <Box position="relative" marginBottom={1}>
      <SimpleBackdrop loading={loadingOrdersList} absolutePosition />
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headTableCheckbox} align="center">
                  <Tooltip
                    title={
                      <Typography align="center">
                        {checkedOrders.length > 0
                          ? 'Desselecionar todos os itens'
                          : 'Selecionar todos os itens desta página'}
                      </Typography>
                    }
                  >
                    <Checkbox
                      onClick={checkedOrders.length > 0 ? handleUncheckAll : handleCheckAll}
                      checked={checkedOrders.length > 0}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.headTableCellId} align="center">
                  Pedido nº
                </TableCell>
                <TableCell className={classes.headTableCellCompany} align="center">
                  Empresa
                </TableCell>
                <TableCell className={classes.headTableCellSource} align="center">
                  Origem
                </TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Data</TableCell>
                <TableCell>NF-e</TableCell>
                <TableCell align="center">Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersList.length > 0 &&
                ordersList.map((order) => (
                  <TableRow key={order.orderId} className={getTableRowColor(selectedOrder, order)}>
                    <TableCell align="center">
                      <Checkbox
                        checked={isCheckedOrder({ orderId: order.orderId })}
                        onClick={() => {
                          if (isCheckedOrder({ orderId: order.orderId })) {
                            handleUncheckOrder({ orderId: order.orderId });
                          } else {
                            handleCheckOrder({ orderId: order.orderId });
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{order.orderId}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <OrderCompanyLogo orderCompany={order.company} />
                    </TableCell>
                    <TableCell align="center">
                      <OrderSourceLogo orderSource={order.source} />
                    </TableCell>
                    <TableCell
                      onClick={() => handleSelectOrder(order.orderId)}
                      className={classes.cursorPointer}
                    >
                      <CustomerNameArea customer={order.customer} />
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => handleSelectOrder(order.orderId)}
                      className={classes.cursorPointer}
                    >{`R$ ${numberToRealWithPeriod(order.orderTotal)}`}</TableCell>

                    <TableCell
                      align="center"
                      onClick={() => handleSelectOrder(order.orderId)}
                      className={classes.cursorPointer}
                    >
                      {formatDateWithHour(order.createdAt)}
                    </TableCell>
                    <TableCell>
                      <InvoiceInquiryLink invoice={order.invoice} />
                    </TableCell>
                    <TableCell
                      onClick={() => handleSelectOrder(order.orderId)}
                      className={classes.cursorPointer}
                    >
                      <OrderStatusTypography orderStatus={order.status} useCustomStatusName />
                      <Typography variant="caption">{order.statusPending}</Typography>
                    </TableCell>
                  </TableRow>
                ))}

              {page !== 1 && ordersList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography
                          onClick={() => handleChangePage()}
                          className={classes.typographyCursor}
                        >
                          Nenhum pedido nesta página, clique aqui para voltar a página.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}

              {page === 1 && ordersList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography>
                          {loadingOrdersList ? 'Carregando' : 'Nenhum pedido para exibir'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Pagination
                        count={Math.ceil(countOrders / limit)}
                        page={page}
                        color="primary"
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
      <DevelopedBy />
    </Box>
  );
}

export default memo(TableOrders);
