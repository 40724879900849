const getProductPrice = (product) => {
  let paymentOfferPrice = parseFloat(product.price.toFixed(2));
  let paymentOfferName = '';
  let paymentOffer = false;

  let offerPercentage = 0;
  let paymentOfferPercentage = 0;

  if (product.price > 0) {
    if (product.offer) {
      paymentOfferPrice = parseFloat(product.offerPrice.toFixed(2));
      offerPercentage = Math.round((product.offerPrice / product.price - 1) * 100);
    }

    const getPaymentMethodOffers = product.offers.filter(
      (objOffer) => objOffer.offerFor === 'payment'
    );

    if (getPaymentMethodOffers.length > 0) {
      const offerWithMaxPercentage = getPaymentMethodOffers.reduce((a, b) =>
        a.discountPercentage > b.discountPercentage ? a : b
      );

      const electedPriceWithPaymentOffer =
        paymentOfferPrice * (1 - offerWithMaxPercentage.discountPercentage / 100);

      paymentOfferPrice = parseFloat(electedPriceWithPaymentOffer.toFixed(2));
      paymentOfferName = offerWithMaxPercentage.offerForCode;
      paymentOffer = true;

      paymentOfferPercentage = Math.round((paymentOfferPrice / product.price - 1) * 100);
    }
  }

  return {
    paymentOfferPrice,
    paymentOfferName,
    paymentOffer,
    offerPercentage,
    paymentOfferPercentage,
  };
};

export default getProductPrice;
