import { Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import SearchField from '../../Common/SearchField';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

function UsersTopBar({
  submitSearch,
  rolesFilters,
  setRolesFilters,
  tagsOptions,
  setTagsOptions,
  hiddenTitle,
}) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        {!hiddenTitle ? (
          <Grid item>
            <Typography variant="h5" className={classes.topBarTitle}>
              Usuários
            </Typography>
          </Grid>
        ) : null}

        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Filtrar por papéis"
                filtersOptions={rolesFilters}
                setFiltersOptions={setRolesFilters}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Filtrar por tags"
                filtersOptions={tagsOptions}
                setFiltersOptions={setTagsOptions}
                multiple={false}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearch} labelSearch="Pesquisar usuário" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default UsersTopBar;
