import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import React, { memo } from 'react';

function TagsButtonGroup({ groupLabel, chipButtons = [] }) {
  return (
    <Grid container direction="column">
      {groupLabel ? (
        <Grid item>
          <Typography variant="caption">{groupLabel}</Typography>
        </Grid>
      ) : null}
      <Grid item>
        <Grid container direction="row" spacing={1}>
          {chipButtons.map((chipButton) => (
            <Grid item key={chipButton.label}>
              <Tooltip
                title={
                  <Typography align="center">
                    {chipButton.value
                      ? `Desmarcar ${chipButton.label}`
                      : `Marcar ${chipButton.label}`}
                  </Typography>
                }
              >
                <Chip
                  onClick={chipButton.onClick}
                  color={chipButton.value ? 'primary' : 'default'}
                  label={chipButton.label}
                  size="small"
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(TagsButtonGroup);
