import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import filterOrderSourceInitial from '../../../constant/filterOrderSourceInitial';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import CustomDatePicker from '../../CustomDatePicker';
import filterOrderStatusInitial from '../../../constant/filterOrderStatusInitial';
import UserAutocomplete from '../../Common/UserAutocomplete';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import UserCityAutocomplete from '../../Common/UserCityAutocomplete';

const useStyles = makeStyles((theme) => ({
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  select: {
    minWidth: 200,
  },
}));

function OrdersReports() {
  const classes = useStyles();

  const [filterOrderSource, setFilterOrderSource] = useState(filterOrderSourceInitial);
  const [filterOrderStatus, setFilterOrderStatus] = useState(filterOrderStatusInitial);
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [userId, setUserId] = useState(null);
  const [seller, setSeller] = useState(null);

  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'month').endOf('month'));

  const [onlyCompanyStock, setOnlyCompanyStock] = useState(false);
  const [loading, setLoading] = useState(false);

  const getReports = async (reportType) => {
    try {
      setLoading(true);

      const response = await Axios.post('/reports/orders/', {
        reportType,
        filterOrderSource,
        filterOrderCompany,
        filterOrderStatus,
        userId,
        sellerId: seller?._id,
        city: selectedCity?.city,
        state: selectedCity?.state,
        startDate: dayjs(startDate).startOf('day'),
        endDate: dayjs(endDate).endOf('day'),
        onlyCompanyStock,
      });

      const { url } = response.data;
      window.open(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleSelectedCustomer = useCallback((_, newValue) => {
    if (newValue?.userId) {
      setOnlyCompanyStock(true);
      return setUserId(newValue?.userId);
    }
    setOnlyCompanyStock(false);
    return setUserId(null);
  });

  const handleOnChangeCity = (_, newValue) => {
    if (newValue) {
      setSelectedCity(newValue);
    } else {
      setSelectedCity(null);
    }
  };

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <CompanySelectOptions
                    inputLabel="Empresa"
                    filtersOptions={filterOrderCompany}
                    setFiltersOptions={setFilterOrderCompany}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Origem do pedido"
                    filtersOptions={filterOrderSource}
                    setFiltersOptions={setFilterOrderSource}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Situação do pedido"
                    filtersOptions={filterOrderStatus}
                    setFiltersOptions={setFilterOrderStatus}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <UserAutocomplete
                      handleOnChange={handleSelectedCustomer}
                      label="Cliente"
                      variant="filled"
                      className={classes.selectBackground}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <UserAutocomplete
                    handleOnChange={(_, newValue) => setSeller(newValue)}
                    selectedUser={seller}
                    label="Vendedor"
                    roles={['staff']}
                    variant="filled"
                    className={classes.select}
                  />
                </Grid>
                <Grid item>
                  <Box width={200} className={classes.selectBackground}>
                    <UserCityAutocomplete
                      handleOnChange={handleOnChangeCity}
                      selectedCity={selectedCity}
                      variant="filled"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data inicial"
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleChangeStartDate}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data final"
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleChangeEndDate}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {loading ? (
            <Grid item marginTop={1}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <Typography>Aguarde. Essa ação pode levar alguns instantes.</Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item marginTop={1}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      getReports('csv');
                    }}
                  >
                    Baixar CSV
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default OrdersReports;
