import { Alert, Box, Button, Grid, Snackbar, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../Common/SimpleDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import OmieResultsDialog from './OmieResultsDialog';
import { formatHandleError } from '../../../helpers/formatData';

function ProcessOrdersDialog({
  openDialogProcessOrders,
  handleCloseDialogProcessOrders,
  checkedOrders,
  handleRefreshAll,
}) {
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [ordersToSendToOmie, setOrdersToSendToOmie] = useState([]);

  const getUpdatedOrderList = useCallback(async () => {
    try {
      setLoadingProcessing(true);
      const ordersToSendToOmieArray = [];

      const response = await Axios.get('/orders/list/id', {
        params: {
          ordersIds: checkedOrders,
        },
      });

      const updatedOrderList = response.data.ordersList;
      setLoadingProcessing(false);

      updatedOrderList.forEach((order) => {
        if (order.status === 'Orçamento') {
          ordersToSendToOmieArray.push(order);
        }
      });

      setOrdersToSendToOmie(ordersToSendToOmieArray);

      return handleRefreshAll();
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar obter a lista de pedidos',
        error,
      });
    }
  }, [checkedOrders]);

  const [omieResultList, setOmieResultList] = useState([]);
  const [openOmieResultsDialog, setOpenOmieResultsDialog] = useState(false);
  const handleOpenOmieResultsDialog = () => setOpenOmieResultsDialog(true);
  const handleCloseOmieResultsDialog = () => setOpenOmieResultsDialog(false);

  const sendOrdersToOmie = async () => {
    try {
      setLoadingProcessing(true);
      const responseOmie = await Axios.post('/omie/orders', {
        ordersIds: ordersToSendToOmie.map((order) => order.orderId),
      });

      if (responseOmie.data && responseOmie.data.length > 0) {
        setOmieResultList(responseOmie.data);
        handleOpenOmieResultsDialog();
      }
      setLoadingProcessing(false);
      return getUpdatedOrderList();
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu durante o envio dos pedidos para a Omie.',
        error,
      });
    }
  };

  useEffect(() => {
    getUpdatedOrderList();
  }, []);

  return (
    <SimpleDialog
      content={
        <>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {ordersToSendToOmie.length > 0
                      ? 'Envia os pedidos com situação de "Orçamento" para a Omie e atualiza a situação do pedido para "Pedido de Venda"'
                      : 'Nenhum pedido com situação "Orçamento" foi selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={sendOrdersToOmie}
                    disabled={!(ordersToSendToOmie.length > 0)}
                  >
                    Enviar pedidos para a Omie
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          {openOmieResultsDialog ? (
            <OmieResultsDialog
              openOmieResultsDialog={openOmieResultsDialog}
              handleCloseOmieResultsDialog={handleCloseOmieResultsDialog}
              omieResultList={omieResultList}
            />
          ) : null}
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <SimpleBackdrop loading={loadingProcessing} />
        </>
      }
      dialogTitle="Processar pedidos"
      handleClose={handleCloseDialogProcessOrders}
      openDialog={openDialogProcessOrders}
    />
  );
}

export default ProcessOrdersDialog;
