import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowRightOutlined from '@mui/icons-material/KeyboardArrowRightOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import OmieConfigAutocomplete from '../../../../../Common/OmieConfigAutocomplete';
import CompanyAutocomplete from '../../../../../Common/CompanyAutocomplete';
import newOmieConfigurationAction from '../../../../../../constant/newOmieConfigurationAction';
import { formatHandleError } from '../../../../../../helpers/formatData';
import ProductsTagAutocomplete from '../../../../../Common/ProductsTagAutocomplete';

const useStyles = makeStyles((theme) => ({
  addConfigIcon: {
    width: '1.4em',
    height: '1.4em',
    color: theme.palette.primary.main,
  },
  configIconClosed: {
    color: theme.palette.primary.main,
    animation: '$openingIcon 0.8s 1',
  },
  configIconOpened: {
    color: theme.palette.primary.main,
    transform: 'rotate(45deg)',
    animation: '$closingIcon 0.8s 1',
  },
  openedTab: {
    fontSize: '1.9rem',
    animation: '$opening 0.5s 1',
  },
  closedTab: {
    fontSize: '1.9rem',
    transform: 'rotate(90deg)',
    animation: '$closing 0.5s 1',
  },
  '@keyframes opening': {
    '0%': {
      transform: 'rotate(90deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes closing': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(90deg)',
    },
  },
  '@keyframes openingIcon': {
    '0%': {
      transform: 'rotate(45deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes closingIcon': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(45deg)',
    },
  },
}));

const reorderOmieActions = (omieActions, initialIndex, endIndex) => {
  const list = Array.from(omieActions);
  const [removed] = list.splice(initialIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

function DialogOmieConfiguration({
  omieConfiguration,
  selectedCompany,
  handleCloseDialog,
  handleCancel,
  getOmieActionsConfig,
}) {
  const classes = useStyles();
  const [selectedActionsConfigId, setSelectedActionsConfigId] = useState([]);
  const [omieConfigurationForm, setOmieConfigurationForm] = useState(omieConfiguration);
  const [omieActionsConfig, setOmieActionsConfig] = useState(newOmieConfigurationAction);
  const [openNewActionConfig, setOpenNewActionConfig] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleSelectActionConfigId = (index) => {
    if (selectedActionsConfigId.includes(index)) {
      return setSelectedActionsConfigId(
        selectedActionsConfigId.filter((i) => selectedActionsConfigId.indexOf(i) !== index),
      );
    }
    return setSelectedActionsConfigId([...selectedActionsConfigId, index]);
  };

  const handleRemoveActionConfig = (actionConfigIndex) => {
    setOmieConfigurationForm((oldFields) => ({
      ...oldFields,
      actionsConfig: oldFields.actionsConfig.filter((_, index) => index !== actionConfigIndex),
    }));
  };

  const handleOpenNewActionConfig = () => {
    if ((!omieActionsConfig.configId || !omieActionsConfig.description) && !openNewActionConfig) {
      return setSnackbar({
        open: true,
        message: 'Selecione uma ação de pedido',
        type: 'info',
      });
    }

    return setOpenNewActionConfig(!openNewActionConfig);
  };

  const handleAddNewAction = () => {
    setOmieConfigurationForm({
      ...omieConfigurationForm,
      actionsConfig: [...omieConfigurationForm.actionsConfig, omieActionsConfig],
    });
    setOpenNewActionConfig(false);
    setOmieActionsConfig(newOmieConfigurationAction);
  };

  useEffect(() => {
    if (!selectedCompany) {
      return setSnackbar({
        open: true,
        message:
          'Parece que nenhuma empresa foi selecionada. Por favor, feche esta janela e tente novamente',
        type: 'error',
      });
    }

    return setOmieConfigurationForm((oldFields) => ({
      ...oldFields,
      company: selectedCompany._id,
    }));
  }, [selectedCompany]);

  useEffect(() => {
    if (JSON.stringify(omieConfigurationForm) !== JSON.stringify(omieConfiguration)) {
      return setDisabledButton(false);
    }
    return setDisabledButton(true);
  }, [omieConfigurationForm, omieConfiguration]);

  const handleSaveForm = async () => {
    if (
      !omieConfigurationForm.name ||
      !omieConfigurationForm.actionsConfig.length ||
      !omieConfigurationForm.company
    ) {
      return setSnackbar({
        open: true,
        message: 'Preencha todos os campos',
        type: 'info',
      });
    }

    try {
      setLoading(true);
      let response;

      if (omieConfigurationForm._id) {
        response = await Axios.put('/omie/config', {
          omieActionsConfig: omieConfigurationForm,
        });
      } else {
        response = await Axios.post('/omie/config', {
          omieActionsConfig: omieConfigurationForm,
        });
      }

      if (!response.data) {
        return setSnackbar({
          open: true,
          message: 'Erro ao salvar configuração',
          type: 'error',
        });
      }
      setLoading(false);
      getOmieActionsConfig();
      return handleCloseDialog();
    } catch (error) {
      setLoading(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar a ação',
        error,
      });
    }
  };

  useEffect(() => {
    setOmieActionsConfig((oldFields) => ({
      ...oldFields,
      tagName: null,
      omieTaxScenario: null,
      omieCategoryCode: null,
      omieCurrentAccountCode: null,
      omiePriceTableCode: null,
    }));
  }, [omieActionsConfig.otherCompany]);

  const handleOnDragEnd = (item) => {
    if (!item.destination) {
      return;
    }
    const newOrdenedList = reorderOmieActions(
      omieConfigurationForm.actionsConfig,
      item.source.index,
      item.destination.index,
    );
    setOmieConfigurationForm((oldFields) => ({
      ...oldFields,
      actionsConfig: newOrdenedList,
    }));
  };

  return (
    <SimpleDialog
      openDialog={!!omieConfiguration}
      handleClose={handleCloseDialog}
      dialogTitle={
        omieConfigurationForm?._id
          ? 'Configuração de Ação de Pedido Omie'
          : 'Nova Configuração de Ação de Pedido Omie'
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Salvar"
      handleCancelButton={handleCancel}
      handleConfirmButton={handleSaveForm}
      disableConfirmButton={disabledButton}
      confirmButtonLoading={loading}
      content={
        <Grid container>
          <Box width={700}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nome da configuração"
                value={omieConfigurationForm.name}
                size="small"
                onChange={(event) => {
                  setOmieConfigurationForm({
                    ...omieConfigurationForm,
                    name: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container padding={1} spacing={0.5}>
                <Grid item xs={12} marginBottom={1}>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={11}>
                      <OmieConfigAutocomplete
                        label="Selecione uma nova ação de pedido"
                        routeName="omieactionsconfig"
                        companyId={selectedCompany._id}
                        selectedValue={omieActionsConfig?.configId}
                        handleOnChange={(_, newValue) => {
                          if (!newValue) {
                            return setOmieActionsConfig((oldFields) => ({
                              ...oldFields,
                              configId: null,
                              description: null,
                            }));
                          }

                          return setOmieActionsConfig((oldFields) => ({
                            ...oldFields,
                            configId: newValue.id,
                            description: newValue.description,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleOpenNewActionConfig}>
                        <AddCircleIcon
                          className={
                            openNewActionConfig
                              ? classes.configIconOpened
                              : classes.configIconClosed
                          }
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {openNewActionConfig ? (
                    <Grid item>
                      <Grid container padding={1} spacing={1}>
                        <Grid item xs={5.5}>
                          <CompanyAutocomplete
                            selectedCompany={omieActionsConfig.otherCompany}
                            label="Outra empresa"
                            setSnackbar={setSnackbar}
                            handleOnChange={(_, newValue) => {
                              if (!newValue) {
                                return setOmieActionsConfig((oldFields) => ({
                                  ...oldFields,
                                  otherCompany: null,
                                }));
                              }

                              return setOmieActionsConfig((oldFields) => ({
                                ...oldFields,
                                otherCompany: newValue._id,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <OmieConfigAutocomplete
                            label="Cenário fiscal"
                            selectedValue={omieActionsConfig.omieTaxScenario}
                            routeName="cenarios"
                            setSnackbar={setSnackbar}
                            companyId={
                              omieActionsConfig.otherCompany
                                ? omieActionsConfig.otherCompany
                                : selectedCompany._id
                            }
                            handleOnChange={(_, newValue) => {
                              if (!newValue) {
                                return setOmieActionsConfig((oldFields) => ({
                                  ...oldFields,
                                  omieTaxScenario: null,
                                }));
                              }

                              return setOmieActionsConfig((oldFields) => ({
                                ...oldFields,
                                omieTaxScenario: newValue.id,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <OmieConfigAutocomplete
                            label="Categoria"
                            selectedValue={omieActionsConfig.omieCategoryCode}
                            routeName="categorias"
                            setSnackbar={setSnackbar}
                            companyId={
                              omieActionsConfig.otherCompany
                                ? omieActionsConfig.otherCompany
                                : selectedCompany._id
                            }
                            handleOnChange={(_, newValue) => {
                              if (!newValue) {
                                return setOmieActionsConfig((oldFields) => ({
                                  ...oldFields,
                                  omieCategoryCode: null,
                                }));
                              }

                              return setOmieActionsConfig((oldFields) => ({
                                ...oldFields,
                                omieCategoryCode: newValue.id,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <OmieConfigAutocomplete
                            label="Conta corrente"
                            selectedValue={omieActionsConfig.omieCurrentAccountCode}
                            routeName="contacorrente"
                            setSnackbar={setSnackbar}
                            companyId={
                              omieActionsConfig.otherCompany
                                ? omieActionsConfig.otherCompany
                                : selectedCompany._id
                            }
                            handleOnChange={(_, newValue) => {
                              if (!newValue) {
                                return setOmieActionsConfig((oldFields) => ({
                                  ...oldFields,
                                  omieCurrentAccountCode: null,
                                }));
                              }

                              return setOmieActionsConfig((oldFields) => ({
                                ...oldFields,
                                omieCurrentAccountCode: newValue.id,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <OmieConfigAutocomplete
                            label="Tabela de preços"
                            selectedValue={omieActionsConfig.omiePriceTableCode}
                            routeName="tabelaprecos"
                            setSnackbar={setSnackbar}
                            companyId={
                              omieActionsConfig.otherCompany
                                ? omieActionsConfig.otherCompany
                                : selectedCompany._id
                            }
                            handleOnChange={(_, newValue) => {
                              if (!newValue) {
                                return setOmieActionsConfig((oldFields) => ({
                                  ...oldFields,
                                  omiePriceTableCode: null,
                                }));
                              }

                              return setOmieActionsConfig((oldFields) => ({
                                ...oldFields,
                                omiePriceTableCode: newValue.id,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <ProductsTagAutocomplete
                            selectedValue={omieActionsConfig.tagName}
                            handleOnChange={(_, newValue) => {
                              if (!newValue) {
                                return setOmieActionsConfig((oldFields) => ({
                                  ...oldFields,
                                  tagName: null,
                                }));
                              }

                              return setOmieActionsConfig((oldFields) => ({
                                ...oldFields,
                                tagName: newValue,
                              }));
                            }}
                            setSnackbar={setSnackbar}
                            label="Tag"
                          />
                        </Grid>
                      </Grid>
                      <Grid container marginLeft={1} marginRight={4}>
                        <Grid item xs={9}>
                          <FormControlLabel
                            control={
                              <Switch
                                value={omieActionsConfig.notGenerateFinancial || false}
                                onChange={() =>
                                  setOmieActionsConfig((oldFields) => ({
                                    ...oldFields,
                                    notGenerateFinancial: !oldFields.notGenerateFinancial,
                                  }))
                                }
                                checked={omieActionsConfig.notGenerateFinancial || false}
                                color="primary"
                              />
                            }
                            label={<Typography variant="button">Não gerar financeiro</Typography>}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button onClick={handleAddNewAction} variant="contained" color="primary">
                            Adicionar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                {omieConfigurationForm.actionsConfig.length ? (
                  <MenuList style={{ width: '100%' }}>
                    <Typography fontWeight={500}>Ações de pedidos configuradas:</Typography>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <Grid {...provided.droppableProps} ref={provided.innerRef}>
                            {omieConfigurationForm.actionsConfig.map((actionConfig, index) => (
                              <Draggable
                                key={String(`${index}`)}
                                draggableId={String(`${index}`)}
                                index={index}
                              >
                                {(prov) => (
                                  <Grid
                                    item
                                    ref={prov.innerRef}
                                    {...prov.draggableProps}
                                    {...prov.dragHandleProps}
                                  >
                                    <Grid item key={String(`${index}`)}>
                                      <MenuItem
                                        style={{
                                          justifyContent: 'space-between',
                                          borderRadius: 10,
                                        }}
                                        onClick={() => handleSelectActionConfigId(index)}
                                      >
                                        <Grid container alignItems="center">
                                          <Grid item>
                                            <IconButton>
                                              <DragIndicatorIcon />
                                            </IconButton>
                                          </Grid>
                                          <Grid item marginTop={0.5}>
                                            <KeyboardArrowRightOutlined
                                              className={
                                                !selectedActionsConfigId.includes(index)
                                                  ? classes.openedTab
                                                  : classes.closedTab
                                              }
                                            />
                                          </Grid>
                                          <Grid item>
                                            <Typography>{actionConfig.description}</Typography>
                                          </Grid>
                                        </Grid>
                                        <IconButton
                                          onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            handleRemoveActionConfig(index);
                                          }}
                                        >
                                          <DeleteOutlineIcon />
                                        </IconButton>
                                      </MenuItem>
                                      {selectedActionsConfigId.includes(index) ? (
                                        <Grid item>
                                          <Grid
                                            container
                                            padding={1}
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Grid item xs={5.5}>
                                              <CompanyAutocomplete
                                                selectedCompany={actionConfig.otherCompany}
                                                key={actionConfig.otherCompany}
                                                label="Outra empresa"
                                                setSnackbar={setSnackbar}
                                                disabled
                                              />
                                            </Grid>
                                            <Grid item xs={5.5}>
                                              <OmieConfigAutocomplete
                                                label="Cenário fiscal"
                                                selectedValue={actionConfig.omieTaxScenario}
                                                key="cenarios"
                                                routeName="cenarios"
                                                setSnackbar={setSnackbar}
                                                disabled
                                                companyId={
                                                  actionConfig.otherCompany
                                                    ? actionConfig.otherCompany
                                                    : selectedCompany._id
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={5.5}>
                                              <OmieConfigAutocomplete
                                                label="Categoria"
                                                selectedValue={actionConfig.omieCategoryCode}
                                                key="categorias"
                                                routeName="categorias"
                                                setSnackbar={setSnackbar}
                                                disabled
                                                companyId={
                                                  actionConfig.otherCompany
                                                    ? actionConfig.otherCompany
                                                    : selectedCompany._id
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={5.5}>
                                              <OmieConfigAutocomplete
                                                label="Conta corrente"
                                                selectedValue={actionConfig.omieCurrentAccountCode}
                                                key="contacorrente"
                                                routeName="contacorrente"
                                                setSnackbar={setSnackbar}
                                                disabled
                                                companyId={
                                                  actionConfig.otherCompany
                                                    ? actionConfig.otherCompany
                                                    : selectedCompany._id
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={4.5}>
                                              <OmieConfigAutocomplete
                                                label="Tabela de preços"
                                                selectedValue={actionConfig.omiePriceTableCode}
                                                key="tabelaprecos"
                                                routeName="tabelaprecos"
                                                setSnackbar={setSnackbar}
                                                disabled
                                                companyId={
                                                  actionConfig.otherCompany
                                                    ? actionConfig.otherCompany
                                                    : selectedCompany._id
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={3.4}>
                                              <ProductsTagAutocomplete
                                                selectedValue={actionConfig?.tagName}
                                                setSnackbar={setSnackbar}
                                                disabled
                                                label="Tag"
                                              />
                                            </Grid>
                                            <Grid item xs={3.5}>
                                              <Typography variant="button">
                                                Gerar financeiro:{' '}
                                                {actionConfig.notGenerateFinancial ? 'Não' : 'Sim'}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Divider style={{ marginTop: 5 }} />
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </MenuList>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Grid>
      }
    />
  );
}

export default DialogOmieConfiguration;
