import React, { memo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  sourceBadge: {
    width: 35,
    height: 35,
    marginTop: 5,
  },
});

const sources = [
  {
    source: 'Loja física',
    title: 'Pedido realizado pela Loja física',
    src: '/assets/imgs/loja-fisica-badge.svg',
  },
  {
    source: 'Site',
    title: 'Pedido realizado pelo Site',
    src: '/assets/imgs/site-badge.svg',
  },
  {
    source: 'App',
    title: 'Pedido realizado pelo App',
    src: '/assets/imgs/mobile-badge.svg',
  },
  {
    source: 'Telemarketing',
    title: 'Pedido realizado pelo Telemarketing',
    src: '/assets/imgs/telemarketing-badge.svg',
  },
];

function OrderSourceLogo({ orderSource }) {
  const classes = useStyles();

  const getSource = sources.find((findSource) => findSource.source === orderSource);

  return getSource ? (
    <Tooltip title={<Typography align="center">{getSource.title}</Typography>}>
      <img className={classes.sourceBadge} src={getSource.src} alt={getSource.title} />
    </Tooltip>
  ) : null;
}

export default memo(OrderSourceLogo);
