const filterProductStatusInitial = [
  {
    id: 'showOnlyActive',
    description: 'Ativos',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'showOnlyUnactive',
    description: 'Inativos',
    active: false,
    default: 'unchecked',
  },
];

export default filterProductStatusInitial;
