const filterCatalogProductOptionsInitial = [
  {
    id: 'showOnlyWithoutImages',
    description: 'Sem imagens',
    active: false,
  },
  {
    id: 'showOnlyWithLessThanThreeImages',
    description: 'Menos de três imagens',
    active: false,
  },
  {
    id: 'showOnlyWithoutDescription',
    description: 'Sem descrição',
    active: false,
  },
  {
    id: 'descriptionToReview',
    description: 'Descrição a revisar',
    active: false,
  },
];

export default filterCatalogProductOptionsInitial;
