import { Box, Grid, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import Axios from 'axios';
import { red } from '@mui/material/colors';
import { changeOrderStatus } from '../../../../../services/melhorGestao/orders';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatHandleError, numberToRealWithPeriod } from '../../../../../helpers/formatData';
import CustomInput from '../../../../CustomInput';

function ChangeOrderStatusButton({
  orderForm,
  setOrderForm = () => {},
  setOrderResult = () => {},
  setInitialOrderForm = () => {},
  formChanged,
  handleSaveForm,
  blockButton,
}) {
  const getNextStatus = () => {
    if (orderForm.status === 'Pedido cancelado') {
      return { name: 'Orçamento', button: 'Criar orçamento' };
    }
    if (orderForm.status === 'Orçamento') {
      return {
        name: 'Pedido criado',
        customName: 'Pedido de Venda',
        button: 'Criar pedido',
        description: 'Ao criar o pedido, não poderá mais ser editado, o estoque será reservado.',
      };
    }
    if (orderForm.status === 'Pedido criado') {
      return {
        name: 'Pedido confirmado',
        customName: 'Acerto Financeiro',
        button: 'Confirmar pedido',
      };
    }

    if (
      orderForm.status === 'Pedido embalado' ||
      orderForm.status === 'Pedido enviado' ||
      orderForm.status === 'Pedido entregue'
    ) {
      return {
        name: 'Pedido concluído',
        customName: 'Concluído',
        button: 'Marcar como concluído',
      };
    }
    return null;
  };

  const nextStatus = getNextStatus();
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [omieConfiguration, setOmieConfiguration] = useState(null);

  const [discountPercentage, setDiscountPercentage] = useState(
    (orderForm.discountTotal / orderForm.orderTotal) * 100,
  );

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const handleOpenConfirmDialog = () => setOpenDialogConfirm(true);
  const handleCloseConfirmDialog = () => setOpenDialogConfirm(false);

  const handleConfirm = async () => {
    setLoadingUpdateStatus(true);

    if (discountPercentage && orderForm.discountTotal > 0) {
      await handleSaveForm({ dontShowMessage: true });
    }

    if (orderForm.discountTotal > orderForm.orderTotal) {
      return setErrorMessage('O valor do desconto não pode ser maior que o valor total do pedido');
    }

    if (!blockButton && nextStatus?.name) {
      if (nextStatus.name === 'Pedido criado') {
        try {
          const response = await Axios.post('/omie/orders', {
            ordersIds: [orderForm.orderId],
          });

          setOrderResult(response.data);
          handleCloseConfirmDialog();
        } catch (error) {
          setLoadingUpdateStatus(false);
          return formatHandleError({
            setSnackbar: (value) => setErrorMessage(value.message),
            defaultMessage: 'Algum erro ocorreu ao enviar o pedido para Omie',
            error,
          });
        }
      }

      const updatedOrder = await changeOrderStatus({
        orderId: orderForm.orderId,
        toStatus: nextStatus.name,
        setLoadingUpdateOrder: setLoadingUpdateStatus,
        setSnackbar: (value) => setErrorMessage(value.message),
      });

      if (nextStatus.description) {
        handleCloseConfirmDialog();
      }

      if (updatedOrder) {
        setOrderForm((oldFields) => ({ ...oldFields, ...updatedOrder }));
        setInitialOrderForm((oldFields) => ({ ...oldFields, ...updatedOrder }));
      }
    }
    return true;
  };

  const handleOpenConfirm = () => handleOpenConfirmDialog();

  const handleChangeDiscountValue = (event) => {
    setDiscountPercentage((event.target.value / orderForm.orderTotal) * 100);
    setOrderForm((oldFields) => ({
      ...oldFields,
      discountTotal: event.target.value,
    }));
  };

  const handleChangeDiscountValueByPercentage = (event) => {
    setDiscountPercentage(event.target.value);
    const priceWithDiscount = orderForm.orderTotal * (event.target.value / 100);
    setOrderForm((oldFields) => ({
      ...oldFields,
      discountTotal: priceWithDiscount,
    }));
  };

  useEffect(() => {
    const getOmieConfiguration = async () => {
      if (!orderForm.othersIds?.idOmieActionConfig || !orderForm?.company) return;
      try {
        const response = await Axios.get(`/omie/config/omie-actions/${orderForm.company}`);

        const omieConfigs = response.data;

        setOmieConfiguration(
          omieConfigs.find(
            (config) => config.actionId === orderForm.othersIds.idOmieActionConfig,
          ) || null,
        );
      } catch (error) {
        formatHandleError({
          setSnackbar: (value) => setErrorMessage(value.message),
          defaultMessage: 'Erro ao buscar configurações da Omie',
          error,
        });
      }
    };
    getOmieConfiguration();
  }, [orderForm?.othersIds?.idOmieActionConfig]);

  return (
    <Box>
      {nextStatus && nextStatus.name === 'Pedido criado' ? (
        <LoadingButton
          loading={loadingUpdateStatus}
          variant="contained"
          color="primary"
          disabled={blockButton}
          onClick={nextStatus.description ? handleOpenConfirm : handleConfirm}
        >
          {nextStatus.button}
        </LoadingButton>
      ) : null}
      {openDialogConfirm ? (
        <SimpleDialog
          openDialog={openDialogConfirm}
          handleClose={handleCloseConfirmDialog}
          dialogTitle={nextStatus.button}
          dialogText={`O status mudará para "${nextStatus.customName}"`}
          content={
            <Box width={400}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography>Ação Omie: {omieConfiguration?.name}</Typography>

                  {omieConfiguration?.actionsConfig.length ? (
                    <Grid container direction="column">
                      {omieConfiguration.actionsConfig.map((config) => (
                        <Grid item key={config._id}>
                          <Typography variant="caption" style={{ marginLeft: 15 }}>
                            - {config.description}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item>
                  <Typography>
                    Total do pedido: R$ {numberToRealWithPeriod(orderForm.orderTotal)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item xs={5}>
                      <Typography>Desconto no pedido</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomInput
                        variant="outlined"
                        number
                        InputStartAdornment="R$"
                        value={orderForm.discountTotal}
                        onChange={handleChangeDiscountValue}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <CustomInput
                        variant="outlined"
                        number
                        InputEndAdornment="%"
                        value={discountPercentage}
                        onChange={handleChangeDiscountValueByPercentage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: orderForm.orderTotal < orderForm.discountTotal ? red[500] : 'black',
                    }}
                  >
                    Total com desconto: R$
                    {numberToRealWithPeriod(orderForm.orderTotal - orderForm.discountTotal)}
                  </Typography>
                </Grid>
                {errorMessage ? (
                  <Grid item>
                    <Typography color="error">{errorMessage}</Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Continuar"
          confirmButtonLoading={loadingUpdateStatus}
          handleCancelButton={handleCloseConfirmDialog}
          handleConfirmButton={handleConfirm}
          disableConfirmButton={formChanged}
        />
      ) : null}
    </Box>
  );
}

export default memo(ChangeOrderStatusButton);
