import React from 'react';
import { Document, PDFViewer } from '@react-pdf/renderer';
import OrderPrint from './OrderPrint';
import LayoutPrint from './LayoutPrint';

function PrintOrderPdf({ selectedCompany, seller, order }) {
  const orderItems = order.items;
  const allItems = [...orderItems.map((item) => ({ isDevolution: false, ...item }))];

  const itemsPerPage = 48;
  const itemsPages = [];

  for (let i = 0; i < allItems.length; i += itemsPerPage) {
    const chunk = allItems.slice(i, i + itemsPerPage);
    itemsPages.push(chunk);
  }

  return (
    <PDFViewer width="1000" height="600" className="app">
      <Document>
        <LayoutPrint selectedCompany={selectedCompany} order={order} seller={seller}>
          {itemsPages.map((items, index) => (
            <OrderPrint
              key={`${index + 1}-${itemsPages.length}`}
              order={order}
              items={items}
              pageNumber={index + 1}
              pageQuantity={itemsPages.length}
            />
          ))}
        </LayoutPrint>
      </Document>
    </PDFViewer>
  );
}

export default PrintOrderPdf;
