import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';

function NotFoundRoute() {
  const navigate = useNavigate();
  const { signed } = useAuth();

  useEffect(() => {
    if (signed) {
      return navigate('/pedidos');
    }
    return navigate('/');
  }, [navigate]);

  return null;
}

export default NotFoundRoute;
