import Axios from 'axios';
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';

import { getUser } from '../../services/melhorGestao/users';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [companies, setCompanies] = useState(JSON.parse(localStorage.getItem('companies')) || []);

  const login = async (email, password, setSnackbar) => {
    try {
      const response = await Axios.post('/users/login/staff', {
        email,
        password,
      });

      const loginData = response.data;

      Axios.defaults.headers.common['token-mg-admin'] = process.env.REACT_APP_API_TOKEN_ADMIN;
      Axios.defaults.headers.common['token-mg'] = loginData.jwtToken;

      const responseCompanies = await Axios.get('/configuration/companies');

      localStorage.setItem('companies', JSON.stringify(responseCompanies.data));
      localStorage.setItem('token', loginData.jwtToken);
      localStorage.setItem('user', JSON.stringify(loginData.user));

      setCompanies(responseCompanies.data);
      setUser(loginData.user);
    } catch (error) {
      Axios.defaults.headers.common['token-mg'] = null;

      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('companies');

      setCompanies([]);
      setUser(null);

      setSnackbar({
        open: true,
        message:
          error?.response?.data?.message || 'Algum erro ocorreu durante o login. Tente novamente.',
        type: 'error',
      });
    }
  };

  const update = async () => {
    try {
      const loggedUser = JSON.parse(localStorage.getItem('user'));
      const loggedToken = localStorage.getItem('token');

      const responseLogin = await Axios.post('/users/login/staff', {
        email: loggedUser.email,
        loggedToken,
      });

      const loginData = responseLogin.data;

      Axios.defaults.headers.common['token-mg'] = null;
      Axios.defaults.headers.common['token-mg'] = loginData.jwtToken;

      const responseCompanies = await Axios.get('/configuration/companies');

      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('companies');

      localStorage.setItem('companies', JSON.stringify(responseCompanies.data));
      localStorage.setItem('token', loginData.jwtToken);
      localStorage.setItem('user', JSON.stringify(loginData.user));

      setCompanies(responseCompanies.data);
      setUser(loginData.user);
    } catch (error) {
      try {
        localStorage.removeItem('user');
        localStorage.removeItem('companies');

        const updatedUser = await getUser(user.userId);
        const responseCompanies = await Axios.get('/configuration/companies');

        localStorage.setItem('companies', JSON.stringify(responseCompanies.data));
        localStorage.setItem('user', JSON.stringify(updatedUser));

        setCompanies(responseCompanies.data);
        setUser(updatedUser);
      } catch (errorInside) {
        console.log(errorInside);
      }
      console.log(error);
    }
  };

  const logout = () => {
    Axios.defaults.headers.common['token-mg'] = null;

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('companies');

    setCompanies([]);
    setUser(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem('companies');

      const responseCompanies = await Axios.get('/configuration/companies');
      localStorage.setItem('companies', JSON.stringify(responseCompanies.data));

      setCompanies(responseCompanies.data);
    };

    if (user) {
      fetchData();
    }
  }, []);

  const authValue = useMemo(
    () => ({
      signed: Boolean(user),
      user,
      companies,
      login,
      update,
      logout,
    }),
    [user],
  );

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
