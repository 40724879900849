import { Alert, Grid } from '@mui/material';
import React from 'react';
import SimpleDialog from '../../../Common/SimpleDialog';

function OmieResultsDialog({
  openOmieResultsDialog,
  handleCloseOmieResultsDialog,
  omieResultList,
}) {
  return (
    <SimpleDialog
      content={
        <Grid container direction="column" spacing={1}>
          {omieResultList &&
            omieResultList.map((omieResult) => (
              <Grid item key={omieResult.idOmieOrder}>
                <Alert severity="success">
                  Pedido criado na Omie: {omieResult.companyName} - Número:{' '}
                  {Number(omieResult.idOmieOrder)}
                </Alert>
              </Grid>
            ))}
        </Grid>
      }
      maxWidth="lg"
      dialogTitle="Resultado do envio de pedidos para a Omie"
      handleClose={handleCloseOmieResultsDialog}
      openDialog={openOmieResultsDialog}
    />
  );
}

export default OmieResultsDialog;
