import Axios from 'axios';
import { Box, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { searchProducts } from '../../../services/melhorGestao/products';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import TableProductsModal from './TableProductsModal';
import { formatProductToOrder } from '../../../helpers/formatData';
import CompanyAutocomplete from '../../Common/CompanyAutocomplete';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import filterProductStatusInitial from '../../../constant/filterProductStatusInitial';

const useStyles = makeStyles({
  paper: {
    height: '90vh',
  },
});

function ProductsModal({
  openProductsModal,
  handleCloseProductsModal,
  handleConfirmSelect,
  productsToDevolution,
  alreadySelectedProducts,
  userItemsBought,
  orderCompany,
  orderSource,
}) {
  const classes = useStyles();
  const [submitedSearchText, setSubmitedSearchText] = useState('');
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [countProducts, setProductsCount] = useState(0);
  const [alreadySelectedProductsList, setAlreadySelectedProductsList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [selectedCompany, setSelectedCompany] = useState(orderCompany);
  const [filtersStatus, setFiltersStatus] = useState(filterProductStatusInitial);
  const [importedProductsFromXlsx, setImportedProductsFromXlsx] = useState([]);

  useEffect(() => {
    if (productsToDevolution) {
      setProductsList(productsToDevolution);
    } else {
      searchProducts({
        searchText: submitedSearchText,
        skip: page * limit - limit,
        limit,
        sortDirection: 'asc',
        setLoadingProductsList,
        setProductsList,
        setProductsCount,
        filterByCompany: selectedCompany?._id || selectedCompany,
        filtersOptions: [...filtersStatus],
      });
    }
  }, [page, limit, selectedCompany, filtersStatus, submitedSearchText]);

  useEffect(() => {
    const fetchData = async (products) => {
      const productsIds = products.map((product) => product.productId);

      const response = await Axios.get('/catalog/products/list/id', {
        params: {
          productsIds,
        },
      });

      const sortedProductsList = response.data.productsList
        .map((product) => ({
          ...product,
          positionOnList: productsIds.indexOf(product.productId),
        }))
        .sort((a, b) => a.positionOnList - b.positionOnList);

      setAlreadySelectedProductsList(
        sortedProductsList.map((product) => {
          let positionOnList = {};

          if (product.positionOnList) {
            positionOnList = {
              positionOnList: product.positionOnList,
            };
          }

          const { quantity, selectedPrice } = products.find(
            (productFind) => product.productId === productFind.productId,
          );

          return {
            ...formatProductToOrder(product),
            ...positionOnList,
            ...(selectedPrice ? { selectedPrice } : { selectedPrice: product.price }),
            quantity,
          };
        }),
      );
    };
    if (importedProductsFromXlsx && importedProductsFromXlsx.length > 0) {
      fetchData(importedProductsFromXlsx);
    } else if (alreadySelectedProducts && alreadySelectedProducts.length > 0) {
      fetchData(alreadySelectedProducts);
    }
  }, [importedProductsFromXlsx]);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, productsList],
  );

  useEffect(() => {
    setPage(1);
  }, [selectedCompany, filtersStatus]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openProductsModal}
      onClose={handleCloseProductsModal}
      classes={{ paper: classes.paper }}
    >
      <Box margin={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Box width={200}>
                <CompanyAutocomplete
                  handleOnChange={(_, newValue) => setSelectedCompany(newValue)}
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                  variant="filled"
                />
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Status"
                filtersOptions={filtersStatus}
                setFiltersOptions={setFiltersStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableProductsModal
          productsList={productsList}
          countProducts={countProducts}
          handleChangePage={handleChangePage}
          limit={limit}
          handleConfirmSelect={handleConfirmSelect}
          productsToDevolution={productsToDevolution}
          setImportedProductsFromXlsx={setImportedProductsFromXlsx}
          alreadySelectedProducts={alreadySelectedProductsList}
          onClose={handleCloseProductsModal}
          submitSearchProducts={setSubmitedSearchText}
          userItemsBought={userItemsBought}
          orderCompany={selectedCompany}
          orderSource={orderSource}
        />
      </Box>
      <SimpleBackdrop loading={loadingProductsList} />
    </Dialog>
  );
}

export default memo(ProductsModal);
