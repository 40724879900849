import Axios from 'axios';
import React, { useState } from 'react';
import { Alert, Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import SimpleDialog from '../../../Common/SimpleDialog';
import { cleanUpDuplicateSpaces, formatHandleError } from '../../../../helpers/formatData';
import LimitTypography from '../../../Common/LimitTypography';

function SpreadsheetDialog({
  openUploadSpreadsheet,
  handleCloseUploadSpreadsheet,
  setImportedProductsFromXlsx,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessages, setAlertMessages] = useState([]);

  const handleFileChange = (event) => {
    setErrorMessage('');
    setAlertMessages([]);
    setSelectedFile(event.target.files[0]);
  };

  const handleConfirmUpload = async () => {
    let withError = false;
    let defaultMessage = 'Algum erro ocorreu ao carregar o arquivo';

    try {
      if (!selectedFile) {
        return false;
      }

      const formData = new FormData();
      formData.append('xlsx', selectedFile);

      const responseXlsx = await Axios.post('/xlsx', formData);
      const tableItems = responseXlsx.data;

      if (!tableItems.length) {
        setErrorMessage('Preencha a planilha');
        return false;
      }

      tableItems.forEach((item) => {
        if (!item.SKU || !item.Nome || !item.Quantidade || !item['Preço Unitário']) {
          withError = true;
          defaultMessage = 'Planilha inválida, baixe o modelo e tente novamente.';
        }
      });

      if (withError) {
        setErrorMessage(defaultMessage);
        return false;
      }

      const alertMessageList = [];

      const responseProducts = await Axios.get('/catalog/products/list/id', {
        params: {
          customSkus: tableItems.map((tableItem) => tableItem.SKU),
        },
      });

      const { productsList } = responseProducts.data;

      tableItems.forEach((tableItem) => {
        if (!productsList.map((ourItem) => ourItem.customSku).includes(String(tableItem.SKU))) {
          withError = true;
          defaultMessage = 'A planilha contém itens não cadastrados';
          alertMessageList.push(`${tableItem.SKU} - ${tableItem.Nome}`);
        }
      });

      if (withError) {
        setErrorMessage(defaultMessage);
        setAlertMessages(alertMessageList);
        return false;
      }

      tableItems.forEach((tableItem) => {
        productsList.forEach((ourItem) => {
          if (ourItem.customSku === String(tableItem.SKU)) {
            if (
              cleanUpDuplicateSpaces(ourItem.technicalName).toLowerCase() !==
              cleanUpDuplicateSpaces(tableItem.Nome).toLowerCase()
            ) {
              withError = true;
              defaultMessage = 'Divergência de nomes da planilha para o sistema';
              alertMessageList.push(
                <Grid container direction="column">
                  <Grid item>
                    {ourItem.customSku} - {ourItem.technicalName}
                  </Grid>
                  <Grid item>Planilha: {tableItem.Nome}</Grid>
                </Grid>,
              );
            }
          }
        });
      });

      if (withError) {
        setErrorMessage(defaultMessage);
        setAlertMessages(alertMessageList);
        return false;
      }

      setImportedProductsFromXlsx(
        tableItems.map((item) => ({
          productId:
            productsList.find((ourItem) => ourItem.customSku === String(item.SKU))?.productId || -1,
          customSku: item.SKU,
          name: item.Nome,
          quantity: item.Quantidade,
          selectedPrice: item['Preço Unitário'],
        })),
      );

      handleCloseUploadSpreadsheet();
      return true;
    } catch (error) {
      return formatHandleError({
        setSnackbar: (value) => setErrorMessage(value.message),
        defaultMessage,
        error,
      });
    }
  };

  const handleDownloadSpreadsheetModel = () => {
    const link = document.createElement('a');
    link.href = '/modelo_import_prod_pedido.xlsx';
    link.download = 'modelo_import_prod_pedido.xlsx';
    link.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === 'file') {
        const file = e.dataTransfer.items[0].getAsFile();
        setSelectedFile(file);
      }
    } else {
      setSelectedFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <SimpleDialog
      openDialog={openUploadSpreadsheet}
      handleClose={handleCloseUploadSpreadsheet}
      dialogTitle="Enviar planilha"
      actionButton={
        <Tooltip title={<Typography>Fazer download da planilha de modelo</Typography>}>
          <IconButton onClick={handleDownloadSpreadsheetModel}>
            <SimCardDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      }
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              sx={{
                border: '1px dotted gray',
                borderRadius: '10px',
                padding: '26px',
                cursor: 'pointer',
              }}
              height={80}
              width={400}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label htmlFor="upload-planilha">
                <input
                  type="file"
                  id="upload-planilha"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {!selectedFile || !selectedFile?.name ? (
                  <Typography>Arrastar ou selecionar um arquivo</Typography>
                ) : (
                  <LimitTypography text={selectedFile.name} maxLength={41} />
                )}
              </label>
            </Box>
          </Grid>
          {errorMessage ? (
            <Grid item>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          ) : null}
          {alertMessages.map((message) => (
            <Grid item key={message}>
              <Box width={400}>
                <Alert severity="info">{message}</Alert>
              </Box>
            </Grid>
          ))}
        </Grid>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      disableConfirmButton={!selectedFile}
      handleConfirmButton={handleConfirmUpload}
      handleCancelButton={handleCloseUploadSpreadsheet}
    />
  );
}

export default SpreadsheetDialog;
