import { Box, Grid, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import TableErrors from './Table';

const useStyles = makeStyles((theme) => ({
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function IntegrationsErrors() {
  const classes = useStyles();

  const [refreshToken, setRefreshToken] = useState(Math.random());
  const [integrationName, setIntegrationName] = useState('');
  const [integrationPlatforms, setIntegrationPlatforms] = useState([]);
  const [integrationsErrors, setIntegrationsErrors] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [sortDirection, setSortDirection] = useState('desc');

  const handleRefresh = () => {
    setPage(1);
    setRefreshToken(Math.random());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePlatforms = await Axios.get('/integrations-errors/platforms');
        setIntegrationPlatforms(responsePlatforms.data);

        if (responsePlatforms.data.length > 0) {
          const platform = integrationName || responsePlatforms.data[0];
          setIntegrationName(platform);

          const responseErrors = await Axios.get('/integrations-errors', {
            params: {
              platform,
              skip: page * limit - limit,
              limit,
              sortDirection,
            },
          });

          setIntegrationsErrors(responseErrors.data.integrationsErrors);
          setTotalResults(responseErrors.data.totalResults);
        } else {
          setIntegrationName('');
          setIntegrationsErrors([]);
          setTotalResults(0);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page, limit, sortDirection, refreshToken, integrationName]);

  const handleChangePage = (next) => {
    let nextTablePage = 0;

    if (next) {
      if (integrationsErrors.length < limit) {
        nextTablePage = page;
      } else {
        nextTablePage = page + 1;
      }
    } else {
      nextTablePage = page - 1;
      if (nextTablePage <= 0) {
        nextTablePage = 1;
      }
    }

    setPage(nextTablePage);
  };

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Paper>
            <Box padding={2}>
              <Typography variant="h5">Erros de integração</Typography>
            </Box>

            {integrationPlatforms.length > 0 ? (
              <MenuList>
                {integrationPlatforms.map((platform) => (
                  <MenuItem
                    key={platform}
                    onClick={() => setIntegrationName(platform)}
                    className={integrationName === platform ? classes.menuItemSelected : null}
                  >
                    {platform}
                  </MenuItem>
                ))}
              </MenuList>
            ) : (
              <MenuList>
                <MenuItem>Nenhuma plataforma encontrada</MenuItem>
              </MenuList>
            )}
          </Paper>
        </Grid>

        <Grid item sm={8}>
          <Paper>
            <Box padding={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">{integrationName || 'Plataforma'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3">
                    {integrationsErrors.length} de {totalResults}
                  </Typography>
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <TableErrors
                  integrationsErrors={integrationsErrors}
                  handleChangePage={handleChangePage}
                  page={page}
                  limit={limit}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  handleRefresh={handleRefresh}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default IntegrationsErrors;
