import { Grid, Typography } from '@mui/material';
import React from 'react';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import getProductPrice from '../../../../helpers/getProductPrice';

function PriceBlock({ product }) {
  const {
    paymentOfferPrice,
    paymentOfferName,
    paymentOffer,
    offerPercentage,
    paymentOfferPercentage,
  } = getProductPrice(product);

  return (
    <Grid container direction="column">
      <Grid item>
        {product.offer || paymentOffer ? (
          <Typography style={{ fontSize: 9, color: 'grey' }}>
            R$ {numberToRealWithPeriod(product.price)}
          </Typography>
        ) : (
          <Typography style={{ fontSize: 13 }}>
            R$ {numberToRealWithPeriod(product.price)}
          </Typography>
        )}
      </Grid>
      {product.offer ? (
        <Grid item>
          <Grid container alignItems="baseline">
            <Grid item>
              <Typography style={{ fontSize: 13 }}>
                R$ {numberToRealWithPeriod(product.offerPrice)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: 9, color: '#020ffa' }}>
                ({offerPercentage}% cartão)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {paymentOffer ? (
        <Grid item>
          <Grid container alignItems="baseline">
            <Grid item>
              <Typography style={{ fontSize: 13 }}>
                R$ {numberToRealWithPeriod(paymentOfferPrice)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: 9, color: '#020ffa' }}>
                ({paymentOfferPercentage}% {paymentOfferName})
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default PriceBlock;
