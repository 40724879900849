import Axios from 'axios';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import TocIcon from '@mui/icons-material/Toc';
import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatDate, formatHandleError, numberToReal } from '../../../../../helpers/formatData';
import SimpleImage from '../../../../Common/SimpleImage';
import mergeOrderItems from '../../../../../helpers/mergeOrderItems';

function ItemsBoughtButton({
  orderForm,
  setUserOrders,
  userItemsBought,
  setUserItemsBought,
  setSnackbar,
}) {
  const [openDialogItemsBought, setOpenDialogItemsBought] = useState(false);
  const handleOpenDialogItemsBought = () => setOpenDialogItemsBought(true);
  const handleCloseDialogItemsBought = () => setOpenDialogItemsBought(false);

  useEffect(() => {
    const fetchUserOrders = async (userId) => {
      try {
        const response = await Axios.get(`/orders/user/${userId}`);
        const { orders } = response.data;

        const mergedItems = mergeOrderItems(
          orders.map((order) =>
            order.items.map((item) => ({
              ...item,
              orderCreatedAt: order.createdAt,
              orderId: order.orderId,
            })),
          ),
        );

        setUserOrders(orders);
        setUserItemsBought(mergedItems);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar pedidos do usuário',
          error,
        });
      }
    };

    if (orderForm.customer.userId) {
      fetchUserOrders(orderForm.customer.userId);
    }
  }, [orderForm.customer]);

  return (
    <Tooltip title={<Typography align="center">Produtos que este cliente já comprou</Typography>}>
      <Box>
        <IconButton onClick={handleOpenDialogItemsBought} size="small">
          <TocIcon />
        </IconButton>
        {openDialogItemsBought ? (
          <SimpleDialog
            openDialog={openDialogItemsBought}
            handleClose={handleCloseDialogItemsBought}
            dialogTitle="Itens que este cliente já comprou"
            content={
              <Box width={900}>
                <Paper elevation={4}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">SKU</TableCell>
                        <TableCell align="center">Imagem</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell align="center">Preço</TableCell>
                        <TableCell align="center">Quantidade</TableCell>
                        <TableCell align="center">Total</TableCell>
                        <TableCell align="center">Pedido</TableCell>
                        <TableCell align="center">Data</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userItemsBought && userItemsBought.length > 0
                        ? userItemsBought.map((item) => (
                            <TableRow key={`${item.orderId}${item.productId}`}>
                              <TableCell align="center">{item.productId}</TableCell>
                              <TableCell align="center">
                                <Box maxWidth="56px" maxHeight="56px">
                                  {item.images && item.images.length > 0 ? (
                                    <SimpleImage
                                      src={item.images[0].src}
                                      imgHeight={50}
                                      imgWidth={50}
                                      alt={item.name}
                                    />
                                  ) : (
                                    <SimpleImage
                                      src="/assets/imgs/no_image.png"
                                      imgHeight={30}
                                      imgWidth={30}
                                      alt={item.name}
                                    />
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell align="center">
                                R$ {numberToReal(item.selectedPrice)}
                              </TableCell>
                              <TableCell align="center">{item.quantity}</TableCell>
                              <TableCell align="center">
                                R$ {numberToReal(item.selectedPrice * item.quantity)}
                              </TableCell>
                              <TableCell align="center">{item.orderId}</TableCell>
                              <TableCell align="center">
                                {formatDate(item.orderCreatedAt)}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Paper>
              </Box>
            }
          />
        ) : null}
      </Box>
    </Tooltip>
  );
}

export default ItemsBoughtButton;
