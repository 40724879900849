import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const searchProducts = async ({
  limit = 20,
  skip = 0,
  searchText = '',
  sortBy = 'productId',
  sortDirection = 'desc',
  setLoadingProductsList,
  setProductsList,
  setProductsCount = () => {},
  setCompaniesConfigurations = () => {},
  filterByTags,
  filterByCompany,
  filterBySupplier,
  filtersOptions,
  filtersByCategories,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingProductsList(true);

    const searchResponse = await Axios.get('/catalog/products/search-admin', {
      params: {
        limit,
        skip,
        searchText,
        sortBy,
        sortDirection,
        filterByTags,
        filterByCompany,
        filterBySupplier,
        filtersOptions,
        filtersByCategories,
      },
    });

    const { totalResults, productsList } = searchResponse.data;

    const companiesResponse = await Axios.get('/configuration/companies');
    const companiesList = companiesResponse.data;
    setCompaniesConfigurations(companiesList);

    const productsListWithCompanies = productsList.map((product) => ({
      ...product,
      stocks: product.stocks.map((stock) => ({
        ...stock,
        company: companiesList.find((company) => stock.company === company._id),
      })),
      prices: product.prices.map((price) => ({
        ...price,
        company: companiesList.find((company) => price.company === company._id),
      })),
    }));

    setProductsList(productsListWithCompanies);
    setProductsCount(totalResults);

    return setLoadingProductsList(false);
  } catch (error) {
    setLoadingProductsList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar os produtos',
      error,
    });
  }
};

export const getProduct = async (productId) => {
  try {
    const response = await Axios.get('/catalog/product-admin', {
      params: {
        id: productId,
      },
    });

    const { product } = response.data;
    return product;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao buscar o produto');
    return null;
  }
};

export const getProducts = async ({ limit, productsIds, allProducts, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.get(`/catalog/products`, {
      params: {
        limit,
        productsIds,
        sortBy: 'productId',
        allProducts,
      },
    });

    const { productsList } = response.data;
    return productsList;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar os produtos',
      error,
    });

    return null;
  }
};

export const createProduct = async ({ product, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.post('/catalog/products', {
      product,
    });

    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar produto',
      error,
    });

    return null;
  }
};

export const updateProduct = async ({ product, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.put(`/catalog/product/${product.productId}`, {
      product,
    });

    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao atualizar produto',
      error,
    });

    return null;
  }
};

export const getProductsResume = async ({
  searchText = '',
  filterByTags = [],
  filterBySupplier = '',
  filtersOptions = [],
  setLoadingProductsResume,
  setFiltersOptions,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingProductsResume(true);

    const response = await Axios.get(`/catalog/products/resume`, {
      params: {
        searchText,
        filterByTags,
        filterBySupplier,
        filtersOptions,
      },
    });

    setFiltersOptions(response.data);
    return setLoadingProductsResume(false);
  } catch (error) {
    setLoadingProductsResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar resumo dos produtos',
      error,
    });
  }
};

export const handlePublishProducts = async ({
  setLoadingPublishedProducts = () => {},
  productsToPublish,
  publishOnShopee,
  publishOnSkyhub,
  publishOnMagazineLuiza,
  publishOnViaVarejo,
  publishOnGoogleShopping,
}) => {
  const results = [];
  setLoadingPublishedProducts(true);

  if (publishOnShopee) {
    try {
      const response = await Axios.post('/shopee/products', { products: productsToPublish });
      results.push({ platform: 'Shopee', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Shopee não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Shopee',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnSkyhub) {
    try {
      const response = await Axios.post('/skyhub/products', { products: productsToPublish });
      results.push({ platform: 'Americanas', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Americanas não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Americanas',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnMagazineLuiza) {
    try {
      const response = await Axios.post('/magazine-luiza/products', {
        products: productsToPublish,
      });
      results.push({ platform: 'Magazine Luiza', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Magazine Luiza não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Magazine Luiza',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnViaVarejo) {
    try {
      const response = await Axios.post('/via-varejo/products', { products: productsToPublish });
      results.push({ platform: 'Via Varejo', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Via Varejo não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Via Varejo',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnGoogleShopping) {
    try {
      const response = await Axios.post('/google-shopping/products', {
        products: productsToPublish,
      });
      results.push({ platform: 'Google Shopping', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Google Shopping não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Google Shopping',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  setLoadingPublishedProducts(false);
  return results;
};
