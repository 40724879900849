import { Grid, Pagination, TableCell, TableFooter, TableRow } from '@mui/material';
import React from 'react';

function UsersTableFooter({ page, totalUsers, limit, handleChangePage }) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={7}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <Pagination
                count={Math.ceil(totalUsers / limit)}
                page={page}
                color="primary"
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default UsersTableFooter;
