import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import {
  formatHandleError,
  formatReal,
  numberToReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../../../helpers/formatData';
import { getUser } from '../../../../../services/melhorGestao/users';
import SimpleBackdrop from '../../../../Common/SimpleBackdrop';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';
import AddressDialog from '../../../../Users/Details/AddressDialog';
import CompanyAutocomplete from '../../../../Common/CompanyAutocomplete';

const useStyles = makeStyles({
  box: {
    minWidth: 430,
  },
  inputAdornment: {
    marginBottom: 0,
  },
});

function ShippingDialog({
  setSnackbar = () => {},
  openDialogShipping,
  handleCloseDialogShipping,
  handleSelectShipping,
  orderForm,
  selectedOrderCompany,
}) {
  const classes = useStyles();
  const [loadingGetShippingValues, setLoadingGetShippingValues] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedAddressIndex, setSelectedAddressIndex] = useState('');

  const [selectedMethod, setSelectedMethod] = useState({});
  const [selectedMethodIndex, setSelectedMethodIndex] = useState('');
  const [highlightShippingOn, setHighlightShippingOn] = useState(
    orderForm?.shipping?.highlightShippingOn || null,
  );

  const [shippingMethods, setShippingMethods] = useState([]);

  const [otherMethodCompany, setOtherMethodCompany] = useState('');
  const [otherMethodValue, setOtherMethodValue] = useState(0);

  const [freeShipping, setFreeShipping] = useState(false);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);

  const [openDialogAddress, setOpenDialogAddress] = useState(false);
  const handleOpenDialogAddress = () => setOpenDialogAddress(true);
  const handleCloseDialogAddress = () => setOpenDialogAddress(false);

  const handleAddAddress = ({ address }) => setAddresses([{ ...address }]);

  useEffect(() => {
    const fetchData = async () => {
      if (orderForm.customer && orderForm.customer.addresses) {
        setAddresses(orderForm.customer.addresses);
      } else if (orderForm.customer && orderForm.customer.userId) {
        const user = await getUser(orderForm.customer.userId);
        setAddresses(user.addresses);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setDisableConfirmButton(true);
    if (selectedAddress && selectedAddress.postalCode && selectedMethod) {
      if (!selectedMethod.otherCompany && selectedMethod.company && selectedMethod.method) {
        setDisableConfirmButton(false);
      }
      if (selectedMethod.otherCompany && otherMethodCompany) {
        setDisableConfirmButton(false);
      }
    }
  }, [selectedAddress, selectedMethod, otherMethodCompany, otherMethodValue]);

  const handleSelectAddress = (event) => {
    setSelectedAddress({ ...addresses[event.target.value] });
    setSelectedAddressIndex(event.target.value);
  };

  const handleSelectMethod = (event) => {
    if (orderForm.status === 'Orçamento') {
      setSelectedMethod({ ...shippingMethods[event.target.value] });
    } else {
      setSelectedMethod({
        ...shippingMethods[event.target.value],
        price: orderForm.shipping.price,
      });
    }
    setSelectedMethodIndex(event.target.value);
  };

  const handleChangeOtherMethodCompany = (event) => {
    setOtherMethodCompany(event.target.value);
  };

  const handleChangeOtherMethodValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setOtherMethodValue(formatReal(value));
  };

  const handleSetFreeShipping = () => {
    setFreeShipping(!freeShipping);
  };

  useEffect(() => {
    if (selectedMethod && selectedMethod.otherCompany) {
      setFreeShipping(false);
    }
  }, [otherMethodCompany, otherMethodValue]);

  const getShippingValues = useCallback(async () => {
    try {
      setLoadingGetShippingValues(true);

      const response = await Axios.get(`/shipping/get-shipping-values`, {
        params: {
          products: orderForm.items.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
          })),
          postalCode: selectedAddress.postalCode,
        },
      });
      const { shippingValues } = response.data;
      setLoadingGetShippingValues(false);

      return setShippingMethods([
        ...shippingValues,
        {
          method: 'Transportadora',
          otherCompany: true,
          companyPicture: '/assets/imgs/mark-ferragens.png',
          price: 0,
          deliveryRange: { min: 1, max: 10 },
        },
      ]);
    } catch (error) {
      setLoadingGetShippingValues(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar os fretes',
        error,
      });
    }
  }, [orderForm.items, orderForm.orderTotal, selectedAddress.postalCode]);

  useEffect(() => {
    if (selectedAddress && selectedAddress.postalCode) {
      getShippingValues();
    }
  }, [selectedAddress]);

  const handleConfirmSelectShipping = useCallback(() => {
    if (selectedAddress && selectedMethod && selectedAddress.postalCode && selectedMethod.method) {
      let selectedCompany = selectedMethod.company;
      let selectedPrice = selectedMethod.price;

      if (selectedMethod.otherCompany) {
        selectedCompany = otherMethodCompany;
        selectedPrice = otherMethodValue;
      }

      const shipping = {
        ...orderForm.shipping,
        ...selectedMethod,
        address: selectedAddress,
        company: selectedCompany,
        highlightShippingOn: highlightShippingOn || selectedOrderCompany,
        price: freeShipping ? 0 : parseFloat(selectedPrice),
      };

      handleSelectShipping({ shipping });
      return handleCloseDialogShipping();
    }
    return false;
  }, [selectedAddress, selectedMethod, otherMethodCompany, otherMethodValue, freeShipping]);

  return (
    <SimpleDialog
      maxWidth="md"
      openDialog={openDialogShipping}
      handleClose={handleCloseDialogShipping}
      dialogTitle="Escolher entrega"
      actionButton={
        <Box width={170} marginRight={1}>
          <CompanyAutocomplete
            label="Destacar frete na"
            selectedCompany={highlightShippingOn}
            handleOnChange={(_, newValue) => {
              if (newValue) {
                setHighlightShippingOn(newValue);
              } else {
                setHighlightShippingOn(null);
              }
            }}
          />
        </Box>
      }
      content={
        <Box className={classes.box}>
          <Grid container direction="column" spacing={2}>
            {addresses && addresses.length > 0 ? (
              <Grid item>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Selecione o endereço para entrega</InputLabel>
                  <Select
                    className={classes.select}
                    onChange={handleSelectAddress}
                    value={selectedAddressIndex}
                  >
                    {addresses &&
                      addresses.map((address, index) => (
                        <MenuItem value={index} key={address._id || address.postalCode}>
                          {address.street.substring(0, 15)}..., {address.streetNumber},{' '}
                          {address.city}/{address.state} - CEP: {address.postalCode}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item>
                <Button onClick={handleOpenDialogAddress} variant="contained" color="primary">
                  Digitar Endereço
                </Button>
                <AddressDialog
                  openDialogAddress={openDialogAddress}
                  handleCloseDialogAddress={handleCloseDialogAddress}
                  handleAddAddress={handleAddAddress}
                />
              </Grid>
            )}

            {shippingMethods.length > 0 ? (
              <Grid item>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Selecione o método de entrega</InputLabel>
                  <Select
                    className={classes.select}
                    onChange={handleSelectMethod}
                    value={selectedMethodIndex}
                  >
                    {shippingMethods.map((shippingMethod, index) => (
                      <MenuItem value={index} key={shippingMethod.company + shippingMethod.method}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <img src={shippingMethod.companyPicture} width={80} alt="" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h3">
                              {shippingMethod.otherCompany
                                ? `Outra ${shippingMethod.method}`
                                : `${shippingMethod.company === 'Mark Ferragens' ? 'Kione' : shippingMethod.company} ${shippingMethod.method} - 
                            ${
                              shippingMethod.price > 0
                                ? `R$ ${numberToReal(shippingMethod.price)}`
                                : 'R$ 0,00'
                            }`}
                            </Typography>
                            <Typography>
                              Prazo de {shippingMethod.deliveryRange.min} a{' '}
                              {shippingMethod.deliveryRange.max} dias úteis
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {selectedMethod && selectedMethod.otherCompany ? (
              <Grid item>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item>
                    <CustomInput
                      label="Nome da transportadora"
                      value={otherMethodCompany}
                      onChange={handleChangeOtherMethodCompany}
                      error={otherMethodCompany === ''}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <CustomInput
                      label="Valor do frete"
                      value={otherMethodValue ? numberToRealWithPeriod(otherMethodValue) : '0,00'}
                      onChange={handleChangeOtherMethodValue}
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {shippingMethods.length > 0 && !(selectedMethod && selectedMethod.otherCompany) ? (
              <Grid item>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            value={freeShipping}
                            onChange={handleSetFreeShipping}
                            checked={freeShipping}
                            name="composition"
                            color="primary"
                          />
                        }
                        label={<Box className={classes.swithSmallLabel}>Frete grátis</Box>}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <CustomInput
                      label="Valor do frete"
                      value={
                        selectedMethod && selectedMethod.price
                          ? numberToRealWithPeriod(selectedMethod.price)
                          : '0,00'
                      }
                      onChange={(event) => {
                        const value = onlyNumbersValue(event.target.value);

                        setSelectedMethod((oldFields) => ({
                          ...oldFields,
                          price: parseFloat(formatReal(value)),
                        }));
                      }}
                      disabled={freeShipping}
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <SimpleBackdrop loading={loadingGetShippingValues} />
          </Grid>
        </Box>
      }
      cancelButtonText="Cancelar"
      disableConfirmButton={disableConfirmButton}
      tooltipConfirmButton="Informe a transportadora e o valor do frete"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseDialogShipping}
      handleConfirmButton={handleConfirmSelectShipping}
    />
  );
}

export default ShippingDialog;
