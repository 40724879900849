import { Grid, MenuItem, Paper, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import {
  CategoryOutlined,
  IntegrationInstructionsOutlined,
  HomeWorkOutlined,
} from '@mui/icons-material';
import TopBarSettings from './TopBarSettings';
import SettingsCategory from './SettingsCategory';
import IntegrationsErrors from './IntegrationsErrors';
import Companies from './Companies';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  menuButtom: {
    padding: '5px',
    width: '70px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomActive: {
    padding: '5px',
    width: '70px',
    height: '64px',
    borderBottom: '2px solid #f49b50',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomInside: {
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 16%)',
    },
    borderRadius: 6,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIconButton: {
    width: '1.4em',
    height: '1.4em',
    color: '#fff',
  },
  menuIcon: {
    width: '1.4em',
    height: '1.4em',
    color: theme.palette.primary.main,
    marginRight: 8,
  },
  menuIconActive: {
    width: '1.4em',
    height: '1.4em',
    color: theme.palette.secondary.main,
    marginRight: 8,
  },
  menuIconSmall: {
    color: theme.palette.primary.main,
  },
  menuIconActiveSmall: {
    color: theme.palette.secondary.main,
  },
}));

const menuItems = [
  {
    name: 'Empresas',
    icon: HomeWorkOutlined,
  },
  {
    name: 'Erros de Integrações',
    icon: IntegrationInstructionsOutlined,
  },
  {
    name: 'Categorias',
    icon: CategoryOutlined,
  },
];

function Settings() {
  const classes = useStyles();

  const [activeMenu, setActiveMenu] = useState(menuItems[0].name);

  return (
    <>
      <TopBarSettings />
      <Paper>
        <Grid container alignItems="center" justifyContent="space-between">
          {menuItems.map((menuItem) => (
            <Grid
              item
              key={menuItem.name}
              xs={12}
              sm={6}
              lg={4}
              onClick={() => setActiveMenu(menuItem.name)}
            >
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <MenuItem onClick={() => setActiveMenu(menuItem.name)}>
                    <SvgIcon
                      component={menuItem.icon}
                      className={
                        activeMenu === menuItem.name ? classes.menuIconActive : classes.menuIcon
                      }
                    />
                    {menuItem.name}
                  </MenuItem>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
      {activeMenu === 'Empresas' && <Companies />}
      {activeMenu === 'Categorias' && <SettingsCategory />}
      {activeMenu === 'Erros de Integrações' && <IntegrationsErrors />}
    </>
  );
}

export default Settings;
