import React from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import UsersPage from '../../../../Users/Page';

function CustomerDialog({
  openCustomerDialog,
  handleCloseCustomerDialog,
  handleSelectCustomerToOrder,
  customerRolesFilters,
}) {
  return (
    <SimpleDialog
      content={
        <UsersPage
          sendToOrderFabButton
          handleSelectCustomerToOrder={handleSelectCustomerToOrder}
          userRolesFilters={customerRolesFilters}
          hiddenTitle
        />
      }
      dialogTitle="Escolha o cliente para o pedido"
      handleClose={handleCloseCustomerDialog}
      openDialog={openCustomerDialog}
    />
  );
}

export default CustomerDialog;
