import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import React, { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Axios from 'axios';
import SimpleBackdrop from '../../Common/SimpleBackdrop';

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: grey[100],
  },
}));

function TableErrors({
  integrationsErrors,
  handleChangePage,
  page,
  limit,
  sortDirection,
  setSortDirection,
  handleRefresh,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const sendSelectedAction = async (errorData, actionName) => {
    try {
      setLoading(true);
      if (actionName === 'Excluir') {
        await Axios.delete('/integrations-errors', {
          params: {
            id: errorData._id,
          },
        });
      }

      if (actionName === 'Tentar novamente') {
        await Axios.patch('/integrations-errors', {
          id: errorData._id,
          functionName: errorData.function,
        });
      }
      setLoading(false);
      handleRefresh();
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleRefresh();
    }
  };

  const handleGetUsefulId = (usefulIdOwner, usefulId, usefulIds) => {
    if (usefulIds && usefulIds.length > 0) {
      return `${usefulIdOwner}s ${usefulIds.join(',')}`;
    }
    if (!usefulId) {
      return 'Sem ID';
    }
    return `${usefulIdOwner} ${usefulId}`;
  };

  return (
    <Paper>
      <SimpleBackdrop loading={loading} />
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">ID Útil</TableCell>
              <TableCell align="center">Tentativas</TableCell>
              <TableCell align="center">Função</TableCell>
              <TableCell align="center">Dados</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrationsErrors &&
              integrationsErrors.length > 0 &&
              integrationsErrors.map((errorData) => (
                <TableRow key={errorData._id}>
                  <TableCell align="center" width={100}>
                    {handleGetUsefulId(
                      errorData.usefulIdOwner,
                      errorData.usefulId,
                      errorData.usefulIds
                    )}
                  </TableCell>
                  <TableCell align="center">{errorData.attempts || 0}</TableCell>
                  <TableCell align="center">{errorData.function}</TableCell>
                  <TableCell align="center" width={350}>
                    <TextField
                      disabled
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      defaultValue={errorData.data || 'Sem dados'}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {errorData.actions.map((action) => (
                      <Tooltip
                        key={action.name}
                        title={<Typography align="center">{action.name}</Typography>}
                      >
                        {action.name === 'Excluir' ? (
                          <IconButton
                            size="small"
                            onClick={() => sendSelectedAction(errorData, action.name)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => sendSelectedAction(errorData, action.name)}
                          >
                            <CropRotateIcon />
                          </IconButton>
                        )}
                      </Tooltip>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            {integrationsErrors && integrationsErrors.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography>Não foi encontrado nenhum erro</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={7}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Typography>Página: {page}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={page === 1}
                      className={classes.closeButton}
                      onClick={() => handleChangePage()}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={integrationsErrors.length < limit}
                      className={classes.closeButton}
                      onClick={() => handleChangePage({ next: true })}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  </Grid>
                  {integrationsErrors.length === limit && (
                    <>
                      <Grid item>
                        <Typography>Sentido: {sortDirection}endente</Typography>
                      </Grid>
                      <Grid item>
                        {sortDirection === 'desc' && (
                          <IconButton onClick={() => setSortDirection('asc')}>
                            <KeyboardArrowUpIcon />
                          </IconButton>
                        )}
                        {sortDirection === 'asc' && (
                          <IconButton onClick={() => setSortDirection('desc')}>
                            <KeyboardArrowDownIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TableErrors;
