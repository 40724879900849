import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import ChangeOrderStatusButton from './ChangeOrderStatusButton';
import PrintOrderIconButton from './PrintOrderIconButton';
import OmieResultsDialog from '../../Dialogs/OmieResultsDialog';

const useStyles = makeStyles((theme) => ({
  submitArea: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: 0,
    width: 662,
    height: 70,
  },
  containerSubmitArea: {
    height: 70,
  },
}));

function SubmitArea({
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  formChanged,
  selectedOrder,
  selectedCompany,
  seller,
  handleSaveForm,
  loadingUpdateOrder,
  confirmCloseProductDetails,
  closeOrderDetails,
  submitSearchOrders,
  setFilterOrderStatus,
  setFilterOrderSource,
  setFilterOrderCompany,
  setSnackbar,
}) {
  const classes = useStyles();

  const [blockButtonStatus, setBlockButton] = useState(true);
  const [blockButtonStatusMessage, setBlockButtonStatusMessage] = useState('');
  const [orderResult, setOrderResult] = useState([]);

  useEffect(() => {
    if (formChanged || !orderForm.orderId) {
      setBlockButtonStatusMessage('Salve o pedido primeiro');
      return setBlockButton(true);
    }

    if (!orderForm?.othersIds || !orderForm?.othersIds?.idOmieActionConfig) {
      setBlockButtonStatusMessage('Escolha uma ação na Omie');
      return setBlockButton(true);
    }

    if (!orderForm?.company) {
      setBlockButtonStatusMessage('Selecione uma empresa');
      return setBlockButton(true);
    }
    setBlockButtonStatusMessage('Alterar situação');
    return setBlockButton(false);
  }, [orderForm, formChanged]);

  const [blockSave, setBlockSave] = useState(true);
  const [blockSaveMessage, setBlockSaveMessage] = useState('');

  useEffect(() => {
    if (!formChanged) {
      setBlockSaveMessage('O pedido não teve alterações');
      return setBlockSave(true);
    }
    if (!orderForm.company) {
      setBlockSaveMessage('Escolha a empresa que fará a venda');
      return setBlockSave(true);
    }
    if (!orderForm.source) {
      setBlockSaveMessage('Escolha a origem do pedido');
      return setBlockSave(true);
    }
    if (!orderForm.customer || !orderForm.customer.name) {
      setBlockSaveMessage('Escolha o cliente');
      return setBlockSave(true);
    }
    if (!orderForm.shipping.method || !orderForm.shipping.company) {
      setBlockSaveMessage('Escolha uma forma de entrega');
      return setBlockSave(true);
    }
    setBlockSaveMessage('Salvar o pedido');
    return setBlockSave(false);
  }, [orderForm, formChanged]);

  return (
    <Paper className={classes.submitArea} elevation={5}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.containerSubmitArea}
      >
        <Grid item>
          <Box marginLeft={2}>
            <Button variant="contained" color="default" onClick={confirmCloseProductDetails}>
              {formChanged ? 'Desfazer alterações' : 'Fechar'}
            </Button>
          </Box>
        </Grid>
        <Tooltip title={<Typography align="center">{blockButtonStatusMessage}</Typography>}>
          <Grid item>
            <ChangeOrderStatusButton
              selectedOrder={selectedOrder}
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              handleSaveForm={handleSaveForm}
              formChanged={formChanged}
              blockButton={blockButtonStatus}
              setInitialOrderForm={setInitialOrderForm}
              submitSearchOrders={submitSearchOrders}
              setFilterOrderStatus={setFilterOrderStatus}
              setFilterOrderSource={setFilterOrderSource}
              setFilterOrderCompany={setFilterOrderCompany}
              closeOrderDetails={closeOrderDetails}
              setOrderResult={setOrderResult}
            />
          </Grid>
        </Tooltip>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <PrintOrderIconButton
                orderForm={orderForm}
                formChanged={formChanged}
                selectedCompany={selectedCompany}
                seller={seller}
                setSnackbar={setSnackbar}
              />
            </Grid>
            <Tooltip title={<Typography align="center">{blockSaveMessage}</Typography>}>
              <Grid item>
                <Box marginRight={2}>
                  <LoadingButton
                    loading={loadingUpdateOrder}
                    variant="contained"
                    color="primary"
                    disabled={blockSave}
                    onClick={handleSaveForm}
                  >
                    Salvar
                  </LoadingButton>
                </Box>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {orderResult.length ? (
        <OmieResultsDialog
          openOmieResultsDialog={!!orderResult.length}
          handleCloseOmieResultsDialog={() => setOrderResult([])}
          omieResultList={orderResult}
        />
      ) : null}
    </Paper>
  );
}

export default SubmitArea;
