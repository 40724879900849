import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Axios from 'axios';
import newOmieConfiguration from '../../../../../constant/newOmieConfiguration';
import DialogOmieConfiguration from './DialogOmieConfiguration';
import DialogRemoveConfiguration from './DialogRemoveConfiguration';
import UserAutocomplete from '../../../../Common/UserAutocomplete';
import OmieConfigAutocomplete from '../../../../Common/OmieConfigAutocomplete';
import { formatHandleError } from '../../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  addConfigIcon: {
    width: '1.4em',
    height: '1.4em',
    color: theme.palette.primary.main,
  },
}));

function OmiePaper({
  omieActionsConfigured,
  setOmieActionsConfigured,
  selectedCompany,
  getOmieActionsConfig,
  setSnackbar,
}) {
  const classes = useStyles();
  const [dialogConfiguration, setDialogConfiguration] = useState(null);
  const [dialogRemoveConfiguration, setDialogRemoveConfiguration] = useState(null);
  const [linkedSellers, setLinkedSellers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sellerConfig, setSellerConfig] = useState({
    hubSeller: null,
    omieSeller: null,
  });

  const handleOpenDialogOmieConfig = (omieConfiguration) => {
    setDialogConfiguration(omieConfiguration);
  };

  const handleCloseDialogOmieConfiguration = () => {
    setDialogRemoveConfiguration(null);
    getOmieActionsConfig();
  };

  const handleOpenDialogRemoveOmieConfig = (action) => {
    setDialogRemoveConfiguration(action);
  };

  const handleDeleteOmieConfiguration = async () => {
    try {
      await Axios.delete(`/omie/config/${dialogRemoveConfiguration.actionId}`);
      setDialogRemoveConfiguration(null);
      getOmieActionsConfig();
    } catch (error) {
      console.error(error);
    }
  };

  const getLinkedSellers = async () => {
    try {
      const response = await Axios.get(`/omie/sellers/${selectedCompany._id}`);
      setLinkedSellers(response.data);
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Erro ao buscar vendedores vinculados',
        error,
      });
    }
  };

  const handleLinkSeller = async (seller, operation) => {
    if (!seller.userId || !seller.sellerOmieId) {
      return setSnackbar({
        open: true,
        message:
          operation === 'add'
            ? 'Selecione um vendedor do Mais Hub e um vendedor da Omie'
            : 'Erro ao desvincular vendedor, recarregue a página e tente novamente',
        type: 'error',
      });
    }

    setLoading(true);

    try {
      const response = await Axios.post('/omie/seller', {
        userId: seller.userId,
        sellerOmieId: seller.sellerOmieId,
        company: selectedCompany,
        operation,
      });

      setSellerConfig({
        hubSeller: null,
        omieSeller: null,
      });

      setSnackbar({
        open: true,
        message: response.data.message,
        type: response.data.success ? 'success' : 'error',
      });
      setLoading(false);
      return getLinkedSellers();
    } catch (error) {
      setSellerConfig({
        hubSeller: null,
        omieSeller: null,
      });
      setLoading(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Erro ao vincular vendedor',
        error,
      });
    }
  };

  useEffect(() => {
    getLinkedSellers();
  }, []);

  return (
    <>
      <Paper>
        <Box padding={2} marginBottom={2}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Ações de Pedidos Configuradas</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={<Typography>Adicionar configuração</Typography>}>
                <IconButton onClick={() => handleOpenDialogOmieConfig(newOmieConfiguration)}>
                  <AddCircleIcon className={classes.addConfigIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            {omieActionsConfigured.length ? (
              <MenuList style={{ width: '100%' }}>
                {omieActionsConfigured.map((action) => (
                  <MenuItem
                    key={action._id}
                    style={{ justifyContent: 'space-between', borderRadius: 10 }}
                    onClick={() => handleOpenDialogOmieConfig(action)}
                  >
                    <Typography>{action.name}</Typography>

                    <IconButton
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleOpenDialogRemoveOmieConfig(action);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </MenuItem>
                ))}
              </MenuList>
            ) : (
              <Grid item textAlign="center" xs={12}>
                <Typography>Nenhuma ação configurada</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5">Vinculação de Vendedores</Typography>
            </Grid>
            <Grid item>
              <Grid container marginTop={1} spacing={1}>
                <Grid item xs={5.6}>
                  <UserAutocomplete
                    setSnackbar={setSnackbar}
                    handleOnChange={(_, newValue) => {
                      if (!newValue) {
                        return setSellerConfig((oldFields) => ({
                          ...oldFields,
                          hubSeller: null,
                        }));
                      }

                      return setSellerConfig((oldFields) => ({
                        ...oldFields,
                        hubSeller: newValue,
                      }));
                    }}
                    selectedUser={sellerConfig.hubSeller}
                    label="Vendedor Mais Hub"
                    roles={['staff']}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={5.6}>
                  <OmieConfigAutocomplete
                    companyId={selectedCompany._id}
                    routeName="vendedores"
                    handleOnChange={(_, newValue) => {
                      if (!newValue) {
                        return setSellerConfig((oldFields) => ({
                          ...oldFields,
                          omieSeller: null,
                        }));
                      }

                      return setSellerConfig((oldFields) => ({
                        ...oldFields,
                        omieSeller: newValue,
                      }));
                    }}
                    selectedValue={sellerConfig.omieSeller}
                    label="Vendedor Omie"
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={0.8}>
                  <IconButton
                    disabled={loading}
                    onClick={() =>
                      handleLinkSeller(
                        {
                          userId: sellerConfig.hubSeller.userId,
                          sellerOmieId: sellerConfig.omieSeller.id,
                          company: selectedCompany,
                        },
                        'add',
                      )
                    }
                  >
                    <AddCircleIcon className={classes.addConfigIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {linkedSellers && linkedSellers.length > 0 ? (
                <Grid container padding={1} marginTop={1}>
                  {linkedSellers.map((seller) => (
                    <Grid item xs={12} key={seller.userId}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography>{`${seller.userId} - ${seller.name} (ID Omie: ${seller.sellerOmieId})`}</Typography>
                        </Grid>
                        <Grid item key={seller} marginBottom={1}>
                          <IconButton
                            disabled={loading}
                            onClick={() => handleLinkSeller(seller, 'remove')}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item textAlign="center" marginTop={2}>
                  <Typography>Nenhum vendedor vinculado</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {dialogConfiguration ? (
        <DialogOmieConfiguration
          omieConfiguration={dialogConfiguration}
          omieActionsConfigured={omieActionsConfigured}
          setOmieActionsConfigured={setOmieActionsConfigured}
          selectedCompany={selectedCompany}
          handleCloseDialog={() => setDialogConfiguration(null)}
          handleCancel={() => setDialogConfiguration(null)}
          getOmieActionsConfig={getOmieActionsConfig}
        />
      ) : null}
      {dialogRemoveConfiguration ? (
        <DialogRemoveConfiguration
          omieConfiguration={dialogRemoveConfiguration}
          handleCloseDialog={handleCloseDialogOmieConfiguration}
          handleConfirm={handleDeleteOmieConfiguration}
          handleCancel={handleCloseDialogOmieConfiguration}
        />
      ) : null}
    </>
  );
}

export default OmiePaper;
