const filterOrderSourceInitial = [
  {
    name: 'Loja física',
    active: false,
  },
  {
    name: 'Telemarketing',
    active: false,
  },
  {
    name: 'Site',
    active: false,
  },
];

export default filterOrderSourceInitial;
