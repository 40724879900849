import { Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import InvoiceInquiryLink from '../../../Common/InvoiceInquiryLink';
import OthersInvoicesButton from './OthersInvoicesButton';

function InvoiceActonsBlok({ orderId, invoice, setSnackbar }) {
  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>Nota Fiscal:</Grid>
                <Grid item>
                  <Typography variant="h3">{invoice.number}</Typography>
                </Grid>
                {invoice?.danfe ? (
                  <Grid item>
                    <Chip onClick={() => window.open(invoice.danfe)} size="small" label="DANFE" />
                  </Grid>
                ) : (
                  <Grid item>
                    <InvoiceInquiryLink invoice={invoice} />
                  </Grid>
                )}
                {invoice?.xml ? (
                  <Grid item>
                    <Chip onClick={() => window.open(invoice.xml)} size="small" label="XML" />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item>
              <OthersInvoicesButton orderId={orderId} setSnackbar={setSnackbar} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InvoiceActonsBlok;
