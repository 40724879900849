import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Axios from 'axios';
import { useAuth } from '../../contexts/auth';

const useStyles = makeStyles({
  loginButtom: {
    width: 300,
    height: 48,
  },
});

function Login() {
  const classes = useStyles();
  const [showLoginPanel, setShowLoginPanel] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const { login } = useAuth();

  useEffect(() => {
    setShowLoginPanel(true);
  }, []);

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }
    if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitLogin = async () => {
    if (email && password) {
      await login(email, password, setSnackbar);
    } else {
      setSnackbar({
        open: true,
        message: 'Digite seu e-mail e senha',
        type: 'error',
      });
    }
  };

  const submitOnEnter = async (event) => {
    if (event.code === 'Enter') {
      submitLogin();
    }
  };

  const handleRecoveryPassword = async () => {
    if (!email) {
      return setSnackbar({
        open: true,
        message: 'Digite um e-mail para recuperar a senha',
        type: 'error',
      });
    }

    try {
      const response = await Axios.post('/users/reset-password', {
        source: 'Kione',
        email,
      });
      return setSnackbar({
        open: true,
        message: response.data.message,
        type: 'info',
      });
    } catch (error) {
      return setSnackbar({
        open: true,
        message: error?.response?.data?.message || 'Erro ao enviar link de redefinição de senha',
        type: 'error',
      });
    }
  };

  return (
    <>
      <Box
        height="100vh"
        width="100vw"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        display="flex"
        bgcolor="#5b7859"
      >
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Zoom in={showLoginPanel} timeout={1000}>
                <Grid container justifyContent="center" alignItems="center">
                  <Box marginBottom={1}>
                    <img alt="Logo Kione" src="/assets/imgs/logo-kione.png" width="210px" />
                  </Box>
                </Grid>
              </Zoom>
            </Grid>

            <Grid item>
              <Zoom in={showLoginPanel} timeout={1000}>
                <Paper elevation={8}>
                  <Box width="340px" padding={2}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <Typography variant="h2" align="center">
                              Acesse sua conta
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Box width="300px" onKeyDown={submitOnEnter}>
                              <TextField
                                fullWidth
                                label="Email"
                                variant="filled"
                                size="small"
                                value={email}
                                name="email"
                                onChange={handleChange}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box width="300px" onKeyDown={submitOnEnter}>
                              <TextField
                                fullWidth
                                label="Senha"
                                variant="filled"
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                name="password"
                                onChange={handleChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              fontWeight={800}
                              style={{ cursor: 'pointer' }}
                              onClick={handleRecoveryPassword}
                            >
                              Esqueci minha senha
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.loginButtom}
                              onClick={submitLogin}
                            >
                              ENTRAR
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Zoom>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container alignItems="center" bottom={0} position="absolute" flexDirection="column">
        <Grid item>
          <Typography fontWeight={400} color="#fff">
            Desenvolvido por
          </Typography>
        </Grid>
        <Grid item marginTop={-0.5}>
          <img alt="Logo Kione" src="/assets/imgs/maishub-branco.png" width={100} />
        </Grid>
      </Grid>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Login;
