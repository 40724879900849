import React from 'react';
import { Grid, Typography } from '@mui/material';
import SimpleImage from '../SimpleImage';

function DevelopedBy() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ marginTop: 5 }}
    >
      <Typography variant="caption" fontWeight={800} align="center">
        Desenvolvido por
      </Typography>
      <SimpleImage
        src="/assets/imgs/maishub-verde.png"
        alt="Mais Hub"
        imgWidth={80}
        style={{ marginTop: -4 }}
      />
    </Grid>
  );
}

export default DevelopedBy;
