import { Grid, IconButton, Menu, MenuItem, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  menuButtom: {
    padding: '5px',
    width: '70px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomActive: {
    padding: '5px',
    width: '70px',
    height: '64px',
    borderBottom: '2px solid #f49b50',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomInside: {
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 16%)',
    },
    borderRadius: 6,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIconButton: {
    width: '1.4em',
    height: '1.4em',
    color: '#fff',
  },
  menuIcon: {
    width: '1.4em',
    height: '1.4em',
    color: theme.palette.primary.main,
    marginRight: 8,
  },
  menuIconActive: {
    width: '1.4em',
    height: '1.4em',
    color: theme.palette.primary.main,
    marginRight: 8,
  },
  menuIconSmall: {
    color: theme.palette.primary.main,
  },
  menuIconActiveSmall: {
    color: theme.palette.primary.main,
  },
  userLoggedName: {
    cursor: 'pointer',
  },
}));

function MobileMenu({
  logout,
  loggedUser,
  menuItems,
  setActiveMenu,
  className,
  openUserDialogDetail,
}) {
  const classes = useStyles();
  const { pathname } = window.location;

  const [menuOrigin, setMenuOrigin] = useState(null);

  const handleOpenMenu = (event) => setMenuOrigin(event.currentTarget);
  const handleCloseMenu = (link) => {
    setMenuOrigin(null);
    setActiveMenu(link);
  };

  return (
    <Grid item className={className}>
      <Grid container alignItems="center" spacing={0}>
        <Grid item onClick={openUserDialogDetail} className={classes.userLoggedName}>
          Olá {loggedUser.name.split(' ')[0]}
        </Grid>
        <Grid item>
          <IconButton onClick={handleOpenMenu}>
            <MenuIcon className={classes.menuIconButton} />
          </IconButton>
        </Grid>
        <Grid item>
          <Menu
            id="basic-menu"
            anchorEl={menuOrigin}
            open={Boolean(menuOrigin)}
            onClose={() => handleCloseMenu('')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.name}
                component={Link}
                to={menuItem.link}
                onClick={() => handleCloseMenu(menuItem.link)}
              >
                <SvgIcon
                  component={menuItem.icon}
                  className={pathname === menuItem.link ? classes.menuIconActive : classes.menuIcon}
                />
                {menuItem.name}
              </MenuItem>
            ))}
            <MenuItem
              component={Link}
              to="/configuracoes"
              onClick={() => handleCloseMenu('/configuracoes')}
            >
              <SettingsIcon
                className={
                  pathname === '/configuracoes' ? classes.menuIconActive : classes.menuIcon
                }
              />
              Configurações
            </MenuItem>
            <MenuItem onClick={logout}>
              <ExitToAppIcon className={classes.menuIcon} />
              Sair/Deslogar
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MobileMenu;
